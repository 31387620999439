import React, { memo, useEffect, useState } from "react";
import RequisitionModel from "../../models/RequisitionModel";
import { CREDIT_GROUP, CREDIT_PERSONAL, CREDIT_EMPLOYEE } from "../../constants/creditTypes";
import _ from "underscore";
import user from "../../settings/user";
import formatString from "../../utilities/format-string";

const ChargeFactor = memo((props) => {

    const [readOnly, setReadOnly] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [prestamo_personalizado, setPrestamoPersonalizado] = useState(false);
    const [warranty, setWarranty] = useState(false);
    const [factor_cobro, setFactorCobro] = useState(undefined);
    const [garantia, setGarantia] = useState('');
    const [garantia_valor, setGarantiaValor] = useState('');
    const [prestamo_personalizado_periodicidad, setPrestamoPersonalizadoPeriodicidad] = useState(0);
    const [cantidad_pagos_semanales, setcantidadPagosSemanales] = useState(0);
    const [charge_factor_type, setChargeFactorType] = useState('');
    const [currentCreditType, setCurrentCreditType] = useState('');
    const [prestamo_personalizado_interes, setPrestamoPersonalizadoInteres] = useState(undefined);
    const [prestamo_personalizado_cantidad_pagos, setPrestamoPersonalizadoCantidadPagos] = useState(0);
    const [hasCreditEmployee, setHasCreditEmployee] = useState(false);
    const [esMaster, setEsMaster] = useState(false);

    const [factors, setFactors] = useState([]);

    const creditTypes = [CREDIT_GROUP, CREDIT_PERSONAL, CREDIT_EMPLOYEE];

    const PERIODICIDAD = {
        '7': 'SEMANAL',
        '14': 'CATORCENAL',
        '28': 'BICATORCENAL',
    };
    
    /**
     * Ordena los factores de cobro, pero aun no se usa porque 
     * modifica el index real y se producen efectos no deseados.
     * Se debe mejorar la sección de la lista de factores de
     * cobro para que esto funcione bien
     */
    const orderChargeFactor = (charge_factors = []) => {
        try {
            const result = _.sortBy(charge_factors, charge_factor => {
                return charge_factor.cantidad_pagos_semanales
            });
            return result;
        } catch (error) {
            return []
        }
    }

    useEffect(() => {
        if (readOnly !== props.readOnly) {
            setReadOnly(props.readOnly);
        }
    }, [props.readOnly, readOnly]);

    useEffect(() => {
        if (factor_cobro !== props.factor_cobro) {
            setFactorCobro(props.factor_cobro);
        }
    }, [props.factor_cobro]);

    useEffect(() => {
        if (prestamo_personalizado !== props.prestamo_personalizado) {
            setPrestamoPersonalizado(!!props.prestamo_personalizado);
        }
    }, [props.prestamo_personalizado]);

    useEffect(() => {
        if (props.charge_factor_type !== charge_factor_type) {
            setChargeFactorType(props.charge_factor_type);
        }
    }, [props.charge_factor_type])

    useEffect(() => {
        if (disabled !== props.disabled) {
            setDisabled(!!props.disabled);
        }
    }, [props.disabled, disabled]);

    useEffect(() => {
        if (warranty !== props.warranty) {
            setWarranty(!!props.warranty);
        }
    }, [props.warranty, warranty]);

    useEffect(() => {
        if (garantia !== props.garantia) {
            setGarantia(props.garantia);
        }
    }, [props.garantia, garantia]);

    useEffect(() => {
        if (garantia_valor !== props.garantia_valor) {
            setGarantiaValor(props.garantia_valor);
        }
    }, [props.garantia_valor, garantia_valor]);

    useEffect(() => {
        if (prestamo_personalizado_periodicidad !== props.prestamo_personalizado_periodicidad) {
            setPrestamoPersonalizadoPeriodicidad(props.prestamo_personalizado_periodicidad);
        }
    }, [props.prestamo_personalizado_periodicidad]);

    useEffect(() => {
        if (prestamo_personalizado_cantidad_pagos !== props.prestamo_personalizado_cantidad_pagos) {
            setPrestamoPersonalizadoCantidadPagos(props.prestamo_personalizado_cantidad_pagos);
        }
    }, [props.prestamo_personalizado_cantidad_pagos, prestamo_personalizado_cantidad_pagos]);

    useEffect(() => {
        if (cantidad_pagos_semanales !== props.cantidad_pagos_semanales) {
            setcantidadPagosSemanales(props.cantidad_pagos_semanales);
        }
    }, [props.cantidad_pagos_semanales, cantidad_pagos_semanales]);

    useEffect(() => {
        if (prestamo_personalizado_interes !== props.prestamo_personalizado_interes) {
            setPrestamoPersonalizadoInteres(props.prestamo_personalizado_interes);
            if (props.prestamo_personalizado_interes) {
                setHasCreditEmployee(true);
            }
        }
    }, [props.prestamo_personalizado_interes]);

    const getChargeFactorType = () => {
        switch (currentCreditType) {
            case CREDIT_GROUP:
                return RequisitionModel.setChargeFactorType(prestamo_personalizado_periodicidad, charge_factor_type, garantia);
            case CREDIT_PERSONAL:
                /**
                 * temporalmente se agrega una validación aquí,
                 * esto es a falta de periodicidad semanal en
                 * los créditos personales.
                 */
                if (prestamo_personalizado_periodicidad === '14' || prestamo_personalizado_periodicidad === 14 || prestamo_personalizado_periodicidad === '28' || prestamo_personalizado_periodicidad === 28) {
                    return RequisitionModel.setChargeFactorType(prestamo_personalizado_periodicidad, charge_factor_type, garantia, prestamo_personalizado);
                }
                return '';
            default:
                return '';
        }
    }

    useEffect(() => {
        try {
            let factorsArray = [];
            let this_charge_factor_type = getChargeFactorType();
            factorsArray = RequisitionModel[this_charge_factor_type];
            
            setChargeFactorType(this_charge_factor_type);
            setFactors(factorsArray || []);

            if (prestamo_personalizado) {
                setCurrentCreditType(CREDIT_PERSONAL);
            } else if (hasCreditEmployee){
                setCurrentCreditType(CREDIT_EMPLOYEE);
            } else {
                setCurrentCreditType(CREDIT_GROUP);
            }
        } catch (error) {
            console.error('Error al desplegar los factores de cobro --> ', error);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prestamo_personalizado, hasCreditEmployee, prestamo_personalizado, prestamo_personalizado_periodicidad, garantia, charge_factor_type]);

    const handleChargeFactor = () => {
        if (prestamo_personalizado && factors) {
            for (let index = 0; index < factors.length; index++) {
                const factor = factors[index];
                if (factor.cantidad_pagos_semanales === cantidad_pagos_semanales &&
                    (index === factor_cobro)) {
                    setFactorCobro(index);
                    if (prestamo_personalizado) {
                        setcantidadPagosSemanales(factor.cantidad_pagos_semanales);
                    }
                    return;
                }
                if (factor.cantidad_pagos_semanales === cantidad_pagos_semanales &&
                    (factor.charge_factor === factor_cobro)) {
                    setFactorCobro(index);
                    if (prestamo_personalizado) {
                        setcantidadPagosSemanales(factor.cantidad_pagos_semanales);
                    }
                    return;
                }
            }
        } else if(factors) {
            for (let index = 0; index < factors.length; index++) {
                const factor = factors[index];
                
                if(index === factor_cobro) {
                    setFactorCobro(index);
                    continue;
                } else if(factor.charge_factor === factor_cobro) {
                    setFactorCobro(index);
                    continue;
                }
            }
        }
    };

    useEffect(() => {
        handleChargeFactor();
        try{
            setEsMaster(props.esMaster());
        }catch(error) {
            console.log(error);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [factors, factor_cobro]);

    return (
        <div className="row">
            {!readOnly && <div className="col-md-12">
                {
                    <div className="alert alert-info" role="alert">Los créditos Master pueden ser solicitados a partir del ciclo 12. Para visualizar los factores de cobro correspondientes, es necesario seleccionar la opción de garantía líquida.</div> 
                }
            </div>}
            
            <div className="col-md-4">
                {(readOnly === false ? <>
                    <label>Tipo de crédito</label>
                    <div className="input-group">
                        <div className="btn-group btn-group-toggle w-100" data-toggle="buttons">
                            <div className="input-group">
                                <select
                                className="form-control text-uppercase"
                                name="options"
                                required
                                onChange={(event) => {
                                    setCurrentCreditType(event.currentTarget.value);
                                    setHasCreditEmployee(event.currentTarget.value === CREDIT_EMPLOYEE);
                                    setChargeFactorType('');
                                    setPrestamoPersonalizadoPeriodicidad('')
                                    if (props.handleChangeDirectState) {
                                        props.handleChangeDirectState(event.currentTarget.value === CREDIT_PERSONAL, 'prestamo_personalizado');
                                        props.handleChangeDirectState(0, 'prestamo_personalizado_periodicidad');
                                    }
                                }}
                                value={currentCreditType}
                                disabled={disabled || (!(user.rol.es_administrador === true) && CREDIT_EMPLOYEE === currentCreditType)}
                                >
                                    <option value="">Tipo de crédito</option>
                                    {creditTypes.map((creditType, index) =>
                                    <option 
                                    key={index}
                                    value={creditType}
                                    disabled={!(user.rol.es_administrador === true) && CREDIT_EMPLOYEE === creditType}>
                                        {creditType} 
                                    </option>)}
                                </select>
                            </div>
                        </div>
                    </div>
            </> : <>
                <small className="text-muted">Tipo de crédito</small>
                <p className="text-uppercase">{currentCreditType}</p>
            </>)}
            </div>

            <div className="col-md-4">
                {(readOnly === false && <>
                    <label htmlFor="prestamo_personalizado_periodicidad">Periodicidad</label>
                    <div className="input-group">
                        <select 
                        className="form-control" 
                        id="prestamo_personalizado_periodicidad" 
                        name="prestamo_personalizado_periodicidad" 
                        label="Periodicidad"
                        value={prestamo_personalizado_periodicidad} 
                        onChange={props.handleChangeSolicitudData} 
                        required={true}
                        disabled={disabled}>
                            <option value="">PERIODICIDAD</option>
                            <option value="7">SEMANAL</option>
                            <option value="14">CATORCENAL</option>
                            <option value="28">BICATORCENAL</option>
                        </select>
                    </div>
                </>) || <>
                    <small className="text-muted">Periodicidad</small>
                    <p>{PERIODICIDAD[prestamo_personalizado_periodicidad]}</p>
                </>}
            </div>
            

            {currentCreditType !== CREDIT_EMPLOYEE && <div className="form-group col-md-4">
                {(readOnly === false && <>
                    <label htmlFor="factor_cobro">Factor de cobro</label>
                    {(currentCreditType === '' && 
                        <span className="form-control text-uppercase" style={{
                            backgroundColor: '#e9ecef',
                            opacity: 1,
                        }}>Elija un tipo de crédito</span>
                    ) || 
                        <select 
                        className="form-control text-uppercase"
                        id="factor_cobro"
                        name="factor_cobro"
                        label="Factor de cobro"
                        onChange={props.handleChargeFactor}
                        required={true}
                        value={factor_cobro || undefined}
                        disabled={disabled || !!!prestamo_personalizado_periodicidad}
                        >
                         <option value="">Factor de cobro</option>
                        
                         <optgroup key="Normal" label="Normal"> 
                            {_.map(factors, (factor, key) => (
                             !factor.esMaster ? 
                            (<option
                            key={key}
                            value={key}
                            disabled={factor.isDisabled(props.ciclo)}>{factor.label}</option>)
                            : null
                            ))}
                        </optgroup>

                        <optgroup key="Master" label="Master"> 
                            {_.map(factors, (factor, key) => (
                            factor.esMaster ? 
                            ( <option
                            key={key}
                            value={key}
                            disabled={factor.isDisabled(props.ciclo)}>{factor.label}</option>)
                            : null
                            ) )}
                        </optgroup>

                        </select>
                    }
                </>) || <>
                    <small className="text-muted">Factor de cobro</small>
                    {
                        prestamo_personalizado
                        ?
                        <p className="text-uppercase">{factors[factor_cobro] && factors[factor_cobro].charge_factor ? formatString.setLegiblePrice(factors[factor_cobro].charge_factor) : ''}</p>
                        :
                        <p className="text-uppercase">{factor_cobro ? formatString.setLegiblePrice(factor_cobro) : ''}</p>
                    }
                </>}
            </div>}

            {currentCreditType === CREDIT_EMPLOYEE && <>
                <div className="form-group mb-3 col-md-4">
                    {(readOnly === false && <>
                        <label htmlFor="prestamo_personalizado_cantidad_pagos">No. pagos</label>
                        <div className="input-group">
                            <input type="number" step="1" className="form-control" id="prestamo_personalizado_cantidad_pagos" name="prestamo_personalizado_cantidad_pagos" label="Cantidad de pagos" value={prestamo_personalizado_cantidad_pagos} min="1" aria-describedby="basic-addon-cantidad-pagos" onChange={props.handleChangeSolicitudData} required={true} disabled={disabled} />
                            <div className="input-group-append">
                                <span className="input-group-text" id="basic-addon-cantidad-pagos">Pagos</span>
                            </div>
                        </div>
                    </>) || <>
                        <small className="text-muted">No. pagos</small>
                        <p className="text-uppercase">{prestamo_personalizado_cantidad_pagos} {prestamo_personalizado_cantidad_pagos > 1 ? 'pagos': 'pago'}</p>
                    </>}
                </div>
            </>}
                
            {currentCreditType === CREDIT_EMPLOYEE && <>
                <div className="form-group mb-3 col-md-4">
                    {(readOnly === false && <>
                        <label htmlFor="prestamo_personalizado_interes">Interés</label>
                        <div className="input-group">
                            <input type="number" className="form-control" id="prestamo_personalizado_interes" name="prestamo_personalizado_interes" label="Interés" value={props.prestamo_personalizado_interes} step="any" min="1" aria-describedby="basic-addon-interes" onChange={props.handleChangeSolicitudData} required={true} disabled={disabled} />
                            <div className="input-group-append">
                                <span className="input-group-text" id="basic-addon-interes">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-percent" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M13.442 2.558a.625.625 0 0 1 0 .884l-10 10a.625.625 0 1 1-.884-.884l10-10a.625.625 0 0 1 .884 0zM4.5 6a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm7 6a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"/>
                                    </svg>
                                </span>
                            </div>
                        </div>
                    </>) || <>
                        <small className="text-muted">Interés</small>
                        <p className="text-uppercase">{`${prestamo_personalizado_interes}%`}</p>
                    </>}
                </div>
            </>}
            
            {(readOnly === false && <>
                <div className="col-md-4">
                    <div className="form-group">
                        <label htmlFor="garantia">Garantía</label>
                        <select 
                        className="form-control" 
                        id="garantia" 
                        name="garantia" 
                        value={garantia} 
                        onChange={props.handleChangeSolicitudData}
                        required={warranty}
                        disabled={disabled}>
                            <option value="">NINGUNO</option>
                            <option value="PRENDARIA">PRENDARIA</option>
                            <option value="LIQUIDA">LÍQUIDA</option>
                        </select>
                        {(esMaster === true && <small id="form-text-garantia" className="form-text text-muted">Se requiere el 10% del monto total como garantía liquida.</small>) || <small id="form-text-garantia" className="form-text text-muted">Requerido si el monto es mayor a {props.prestamo_personalizado===true?'$9,500':'$25,000'}.</small>}
                        
                    </div>
                </div>
                {!_.isEmpty(garantia) && <div className="col-md-4">
                    {garantia === 'PRENDARIA' && <div className="form-group">
                        <label htmlFor="garantia_valor">Prenda</label>
                        <input type="text" className="form-control text-uppercase" id="garantia_valor" name="garantia_valor" label="Garantía" value={garantia_valor} onChange={props.handleChangeSolicitudData} required={!_.isEmpty(garantia)} disabled={disabled}/>
                    </div>}
                    {garantia === 'LIQUIDA' && <div className="form-group">
                        <label htmlFor="garantia_valor">Monto</label>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text">$</span>
                            </div>
                            <input type="number" className="form-control" id="garantia_valor" name="garantia_valor" label="Garantía" value={garantia_valor} onChange={props.handleChangeSolicitudData} required={!_.isEmpty(props.garantia)} disabled={disabled}/>
                        </div>
                    </div>}
                </div>}
            </>) || <>
                {!_.isEmpty(garantia) && <div className="col-md-4">
                    {garantia === 'PRENDARIA' && <div className="form-group">
                        <small className="text-muted">Garantía prenda</small>
                        <p>{garantia_valor}</p>
                    </div>}
                    {garantia === 'LIQUIDA' && <div className="form-group">
                        <small className="text-muted">Garantía monto</small>
                        <p>{new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(garantia_valor)}</p>
                    </div>}
                </div>}
            </>}
        </div>
    );
});

export default ChargeFactor;