import React, { Component } from 'react';
import GroupForm from './GroupForm';
import GroupSpinner from './GroupSpinner';
import Parse from 'parse';
import user from './../../settings/user';
import config from './../../settings/config';
import textUtils from './../../utilities/format-string';
import RequisitionModel from '../../models/RequisitionModel'
import Rest from './../../Server/Rest';
import _ from 'underscore';
import { saveIdBusqueda } from '../../services/group';
import { addSolicitud, saveAmounts, setAmounts, updatePartnersStatus } from '../../services/requisition';

class GroupRegister extends Component {
    constructor(props) {
        super(props);
        this.state = this.state_init();

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    state_init = () => {
        this.data = {};
        this.group_name_exist = false;
        return {
            resetform: false,
            savedGroup: false,
            savedMontos: false,
            finish: false,
            saving: false,
            grupo_reducido: false,
            charge_factor_type: 'charge_factors_6'
        }
    };

    setData = (data) => {
        this.data = data;
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        });
    }

    handleResetForm = () => {
        this.setState({
            resetform: true
        });
        setTimeout(() => {
            this.setState({
                resetform: false
            });
        }, 100);
    }

    validateAmounts = (amount_name) => {
        let isZero = false;
        _.each(this.data.montos, (item) => {
            if (
                _.isUndefined(item[amount_name]) ||
                item[amount_name] === '' ||
                item[amount_name] === 0 ||
                item[amount_name] === '0'

            ) {
                isZero = true;
            }
        });
        return isZero;
    }

    saveSolicitud = async (
        _amounts, 
        groupId, 
        Sucursal, 
        dia_pago, 
        ciclo, 
        cantidad_pagos_semanales, 
        fecha_elaboracion, 
        factor_cobro,
        prestamo_personalizado,
        prestamo_personalizado_cantidad_pagos,
        prestamo_personalizado_periodicidad,
        prestamo_personalizado_interes,
        garantia,
        garantia_valor,
        cuentas_bancarias,
        meses = undefined,
        interes = undefined,
        iva_del_interes = undefined,
        charge_factor_type = undefined
    ) => {

        const currentUser = Parse.User.current();

        let data = {};

        let amounts = _.pluck(_amounts, 'success');

        data.registeredBy = {
            __type: 'Pointer',
            className: '_User',
            objectId: currentUser.id
        };

        data.montos = _.map(amounts, amout => {
            return {
                __type: 'Pointer',
                className: 'SolicitudesMontos',
                objectId: amout.objectId
            }
        });

        data.promotor = {
            __type: 'Pointer',
            className: 'Empleados',
            objectId: this.data.promotor
        };

        data.responsable = {
            __type: 'Pointer',
            className: 'Socios',
            objectId: this.data.responsable
        };

        data.Grupo = {
            __type: 'Pointer',
            className: 'Grupos',
            objectId: groupId
        };

        data.Sucursal = Sucursal;

        data.status = 'autorizacion';

        data.ciclo = ciclo || {__op:'Delete'};
        data.dia_pago = dia_pago || {__op:'Delete'};
        data.cantidad_pagos_semanales = cantidad_pagos_semanales || {__op:'Delete'};
        data.fecha_elaboracion = fecha_elaboracion || {__op:'Delete'};
        data.factor_cobro = factor_cobro || {__op:'Delete'};
        data.prestamo_personalizado = prestamo_personalizado === true || {__op:'Delete'};
        data.prestamo_personalizado_cantidad_pagos = prestamo_personalizado_cantidad_pagos || {__op:'Delete'};
        data.prestamo_personalizado_periodicidad = prestamo_personalizado_periodicidad || {__op:'Delete'};
        data.prestamo_personalizado_interes = prestamo_personalizado_interes || {__op:'Delete'};
        data.garantia = garantia || {__op:'Delete'};
        data.garantia_valor = (!_.isEmpty(garantia) && garantia_valor) || {__op:'Delete'};
        data.cuentas_bancarias = (this.data.modo_desembolso === 'BANCO' && this.validCuentasBancarias(cuentas_bancarias) )|| {__op:'Delete'};
        data.charge_factor_type = charge_factor_type || this.state.charge_factor_type;
        // if (!(data.prestamo_personalizado === true)) {
        //     data.meses = meses;
        //     data.interes = interes;
        //     data.iva_del_interes = iva_del_interes;
        // }

        data.fecha_registro = {
            __type: 'Date',
            iso: new Date().toISOString()
        }

        return Rest.peticion(`${config.api_url}/classes/Solicitudes`, {
            method: 'POST',
            body: JSON.stringify(data)
        });
    }

    whereValidName = () => {
        return {
            Grupo: {
                $select: {
                    query: {
                        className: 'Grupos',
                        where: {
                            nombre: this.data.nombre.toUpperCase().trim(),
                            Sucursal: {
                                __type: 'Pointer',
                                className: 'Sucursales',
                                objectId: this.data.Sucursal
                            }
                        }
                    },
                    key: 'objectId'
                }
            }
        }
    }

    handleValidName = () => {
        if (
            !_.isEmpty(this.data.nombre) &&
            !_.isEmpty(this.data.Sucursal)
        ) {
            const params = new URLSearchParams({
                where: JSON.stringify(this.whereValidName()),
                count: 1,
                limit: 0
            });
            return Rest.peticion(`${config.api_url}/classes/GruposDiccionario?${params.toString()}`, {
                method: 'GET'
            })
            .then(res => {
                return res.json();
            })
            .then(res => {
                if (res.code) {
                    console.log('Ocurrió un error al comprobar la existencia del nombre de grupo.');
                } else {
                    if (res.count > 0) {
                        alert('El nombre del grupo ya está en uso. Escriba uno distinto.');
                        this.group_name_exist = true;
                    } else {
                        this.group_name_exist = false;
                    }
                }
                return res;
            })
            .catch(error => {
                alert('Ocurrió un error al comprobar la existencia del nombre de grupo.');
                return error;
            });
        }
        
    }

    validCuentasBancarias = (cuentas_bancarias) => {
        let result = _.filter(cuentas_bancarias, cuenta => {
            return cuenta.Socio.objectId.length;
        });

        if (!result.length) {
            result = {__op: 'Delete'};
        }
        
        return result;
    }

    setChargeFactorType = (prestamo_personalizado_periodicidad, garantia) => {
        if ((prestamo_personalizado_periodicidad === 7 || prestamo_personalizado_periodicidad === '7') && garantia === 'LIQUIDA') {
            return 'charge_factors_7';
        } else if (prestamo_personalizado_periodicidad === 7 || prestamo_personalizado_periodicidad === '7') {
            return 'charge_factors_6';
        } else if ((prestamo_personalizado_periodicidad === 14 || prestamo_personalizado_periodicidad === '14') && garantia === 'LIQUIDA') {
            return 'charge_factors_9';
        } else if (prestamo_personalizado_periodicidad === 14 || prestamo_personalizado_periodicidad === '14') {
            return 'charge_factors_8';
        } else if ((prestamo_personalizado_periodicidad === 28 || prestamo_personalizado_periodicidad === '28') && garantia === 'LIQUIDA') {
            return 'charge_factors_10';
        }else if ((prestamo_personalizado_periodicidad === 28 || prestamo_personalizado_periodicidad === '28') ) {
            return 'charge_factors_11';
        }
    }


    validClient = (amount_name, montos) => {
        let clients = 0;
        _.each(montos, () => {
            clients += 1;        
        });
        
        return clients;
    }

    validAmount = (amount_name, montos) => {
        let amountV = true;
        _.each(montos, (item) => {
            if (item[amount_name] === '') {
               amountV = false;
            }else{
                if(parseInt(item[amount_name]) < 5000){
                    amountV = false;
               }
            }           
        });
        return amountV;
    }    

    setAmountTotal_0 = (amount_name, montos) => {
        let sum = 0;
        _.each(montos, (item) => {
            if (item[amount_name] === '') {
                sum += 0;
            } else {
                sum += parseInt(item[amount_name]);
            }
            
        });
        return sum;
    }

    esMaster = () => {
        if (this.data.charge_factor_type && this.data.factor_cobro) {
            const chargeFactor = RequisitionModel[this.data.charge_factor_type.toLowerCase()][this.data.factor_cobro];
            if(chargeFactor)
                return chargeFactor.esMaster;
            else
                return false;
        }
        return false;
    }    


    validarPrestamoPersonalizado = () => {
        let error = false;
        if(this.data.prestamo_personalizado)
        {
            this.state.grupo_reducido = true;
            this.data.grupo_reducido = true;
            
            if( this.validClient('monto_solicitado', this.data.montos) > 1){
                alert('Se requiere un solo integrante para crédito individual.');
                return true;
            }
 
            if( this.data.garantia != "LIQUIDA"){
                alert('Se requiere una garantía líquida.');
                return true;
            }

            if( this.data.garantia_valor < (this.setAmountTotal_0('monto_sugerido', this.data.montos) * 0.10) ){
                alert('Se requiere mínimo el 10% del monto total como garantía líquida.');
                return true;
            }

            if( !this.validAmount('monto_sugerido', this.data.montos) ){
                alert('Se requiere monto mínimo de $5,000 para cada integrante.');
                return true; 
            }            
        }

        return error;
    }
    

    validarMaster = () => {
        let error = false;
        if(this.esMaster()){
            if( this.data.garantia_valor < (this.setAmountTotal_0('monto_sugerido', this.data.montos) * 0.10) ){
                alert('Se requiere mínimo el 10% del monto total como garantía líquida.');
                return true;
            }

            if( !this.validAmount('monto_sugerido', this.data.montos) ){
                alert('Se requiere monto mínimo de $5,000 para cada integrante.');
                return true;
            }
        }

        return error;
    }


    handleSubmit = async (event) => {

        if (event) event.preventDefault();

        await this.handleValidName();

        if(this.validarPrestamoPersonalizado())
        {
            return;
        }

        if(this.validarMaster())
        {
            return;
        }
    
        if((this.data.prestamo_personalizado === false && !this.data.prestamo_personalizado_interes) && this.data.Socios.length < 3) {
            alert('Se requiere por lo menos 3 clientas para conformar el grupo.');
            return;
        }

        if (this.validateAmounts('monto_solicitado') || this.validateAmounts('monto_sugerido')) {
            alert('Debe especificar los montos solicitados y sugeridos antes de guardar.');
            return;
        }

        if (this.group_name_exist === true) {
            return;
        }

        const r = window.confirm('¿Todos los datos son correctos?');

        if (r === true) {
            const currentUser = Parse.User.current();

            let data = _.clone(this.data);

            if (currentUser.toJSON().Sucursal) {
                data.Sucursal = currentUser.toJSON().Sucursal.objectId;
            }
        
            data.fecha_registro = {
                __type: 'Date',
                iso: new Date().toISOString()
            };

            data.promotor = {
                __type: 'Pointer',
                className: 'Empleados',
                objectId: data.promotor
            };

            data.responsable = {
                __type: 'Pointer',
                className: 'Socios',
                objectId: data.responsable
            };

            // Establecer usuario quien registró el socio
            data.registeredBy = {
                __type: 'Pointer',
                className: '_User',
                objectId: currentUser.id
            };

            data.Socios = _.map(data.Socios, objectId => {
                return {
                    __type: 'Pointer',
                    className: 'Socios',
                    objectId: objectId
                }
            });

            const toDay = new Date();
            data.fecha_elaboracion.setHours( toDay.getHours() );
            data.fecha_elaboracion.setMinutes( toDay.getMinutes() );

            data.fecha_elaboracion = {
                __type: 'Date',
                iso: data.fecha_elaboracion.toISOString()
            };

            // formatear datos
            data = RequisitionModel.formatData(data);
            
            if (
                data.modo_desembolso === 'TARJETA_DEBITO' &&
                data.responsable_tdd_numero.length < 16
            ) {
                alert('El Número de tarjeta no es correcto. corrija e intente de nuevo.');
                return;
            }

            const amounts = _.clone(data.montos);
            const ciclo = data.ciclo;
            const fecha_elaboracion = data.fecha_elaboracion;
            const prestamo_personalizado = data.prestamo_personalizado;
            const garantia = data.garantia;
            const garantia_valor = data.garantia_valor;
            const cuentas_bancarias = data.cuentas_bancarias;
            const dia_pago = data.dia_pago;
            const cantidad_pagos_semanales = data.cantidad_pagos_semanales;
            const prestamo_personalizado_cantidad_pagos = data.prestamo_personalizado_cantidad_pagos;
            const prestamo_personalizado_periodicidad = data.prestamo_personalizado_periodicidad;
            const prestamo_personalizado_interes = data.prestamo_personalizado_interes;
            let meses = undefined;
            let interes = undefined;
            let iva_del_interes = undefined;
            let charge_factor_type = RequisitionModel.setChargeFactorType(data.prestamo_personalizado_periodicidad, undefined, garantia, prestamo_personalizado);
            const factor_cobro = prestamo_personalizado ? RequisitionModel[charge_factor_type][data.factor_cobro].charge_factor : data.factor_cobro;
            // if (!(prestamo_personalizado === true)) {
            //     meses = RequisitionModel[this.setChargeFactorType(prestamo_personalizado_periodicidad, garantia)][factor_cobro].meses;
            //     interes = RequisitionModel[this.setChargeFactorType(prestamo_personalizado_periodicidad, garantia)][factor_cobro].interes;
            //     iva_del_interes = RequisitionModel[this.setChargeFactorType(prestamo_personalizado_periodicidad, garantia)][factor_cobro].iva_del_interes;
            // }
            
            delete data.montos;
            delete data.ciclo;
            delete data.dia_pago;
            delete data.cantidad_pagos_semanales;
            delete data.fecha_elaboracion;
            delete data.factor_cobro;
            delete data.prestamo_personalizado;
            delete data.prestamo_personalizado_cantidad_pagos;
            delete data.prestamo_personalizado_periodicidad;
            delete data.prestamo_personalizado_interes;
            delete data.garantia;
            delete data.garantia_valor;
            delete data.cuentas_bancarias;

            let groupId = '';

            if (this.state.grupo_reducido === true) {
                data.grupo_reducido = true
            } else {
                data.grupo_reducido = false
            }

            data.status = 'autorizacion';

            data.id_busqueda = textUtils.setIdBusqueda(data.nombre);

            this.setState({
                saving: true
            });

            return Rest.peticion(`${config.api_url}/classes/Grupos`, {
                method: 'POST',
                body: JSON.stringify(data)
            }).then(res => {

                return res.json();

            }).then(async res => {
                
                this.setState({
                    savedGroup: true
                });

                groupId = res.objectId;

                const _amounts = setAmounts(amounts, res.objectId);

                return await saveAmounts(_amounts);
                
            }).then(async res => {

                this.setState({
                    savedMontos: true
                });
                
                return await this.saveSolicitud(
                    res, 
                    groupId, 
                    data.Sucursal, 
                    dia_pago, 
                    ciclo, 
                    cantidad_pagos_semanales, 
                    fecha_elaboracion, 
                    factor_cobro,
                    prestamo_personalizado,
                    prestamo_personalizado_cantidad_pagos,
                    prestamo_personalizado_periodicidad,
                    prestamo_personalizado_interes,
                    garantia,
                    garantia_valor,
                    cuentas_bancarias,
                    meses,
                    interes,
                    iva_del_interes,
                    charge_factor_type
                );
                
            }).then(res => {

                return res.json();
                
            }).then(async res => {

                return await addSolicitud(res.objectId, groupId);
                
            }).then(async () => {

                return await saveIdBusqueda(groupId, textUtils.setIdBusqueda(data.nombre));

            })
            .then(async () => {

                return await updatePartnersStatus(amounts);

            })
            .then(() => {
                setTimeout(() => {
                    alert('El grupo ha sido guardado.');
                    let state_init = this.state_init();
                    state_init.resetform = true;
                    this.setState(state_init);
                    setTimeout(() => {
                        this.setState(this.state_init());
                    }, 200);
                }, 200);
            }).catch(error => {
                console.log('my error', error);
                alert('No se ha podido guardar el grupo. Intente más tarde.');
                let state_init = this.state_init();
                state_init.loading = false;
                this.setState(state_init);
                setTimeout(() => {
                    state_init.resetform = false;
                    this.setState(this.state_init());
                }, 200);
            })
            .finally(() => {
                this.setState({
                    finish: true
                });
            });
        }
    }

    render() {
        return (
            <>
                <GroupSpinner loading={this.state.saving} savedGroup={this.state.savedGroup} savedMontos={this.state.savedMontos} finish={this.state.finish} />
                <div className="row align-items-center justify-content-between">
                    <div className="col-md-6">
                        <h3 className="text-uppercase text-pink pt-3 pb-3 m-0">
                            <i className="fas fa-users-cog mr-2"></i>
                            Nuevo crédito
                        </h3>
                    </div>
                </div>
                
                <div className="card solfin-card">
                    <div className="card-body">
                        <p className="text-info">
                            Todos los campos son obligatorios.
                        </p>
                        <form onSubmit={this.handleSubmit}>
                            <GroupForm required={true} handleValidName={this.handleValidName} resetform={this.state.resetform} setData={this.setData} readOnly={false}/>
                            <div className="form-group">
                                { /*
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="grupo_reducido" name="grupo_reducido" checked={this.state.grupo_reducido} onChange={this.handleChange} />
                                    <label className="form-check-label" htmlFor="grupo_reducido">
                                        Permitir grupo reducido (menos de 3 clientas)
                                    </label>
                                </div>
                               */ }
                            </div>
                            <br/>
                            <button type="submit" className="btn btn-primary btn-lg">Guardar</button>
                        </form>
                    </div>
                </div>
                <div className="p-5"></div>
            </>
        );
    }
}

export default GroupRegister;