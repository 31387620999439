import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import config from './../../settings/config';
import Rest from './../../Server/Rest';
import _ from 'underscore';
import "react-datepicker/dist/react-datepicker.css";
import user from './../../settings/user';
import { registerLocale } from "react-datepicker";
import PopOver from '../others/Popover';
import Spinner from '../others/Spinner';
import requisitions from '../../services/requisitions';
import RequisitionModel from '../../models/RequisitionModel';
import es from 'date-fns/locale/es';
import ChargeFactor from '../forms/ChargeFactor';
registerLocale('es', es);

const GroupRequisitionFastUpdate = (props) => {
    const [state, setState] = useState({
        loading: false,
        ciclo: 1,
        fecha_elaboracion: '',
        dia_pago: '',
        cantidad_pagos_semanales: 0,
        factor_cobro: 0,
        prestamo_personalizado: false,
        prestamo_personalizado_cantidad_pagos: 0,
        prestamo_personalizado_periodicidad: 0,
        prestamo_personalizado_interes: 0,
        garantia: '',
        garantia_valor: '',
        charge_factor_type: ''
    });

    const [fecha_elaboracion, setFechaElaboracion] = useState(null);

    useEffect(() => {
        const {
            ciclo,
            fecha_elaboracion,
            dia_pago,
            cantidad_pagos_semanales,
            factor_cobro,
            prestamo_personalizado,
            prestamo_personalizado_cantidad_pagos,
            prestamo_personalizado_periodicidad,
            prestamo_personalizado_interes,
            garantia,
            garantia_valor,
            charge_factor_type,
            status,
        } = props.requisition;

        setFechaElaboracion(fecha_elaboracion);

        setState(prevState => ({
            ...prevState,
            ciclo,
            fecha_elaboracion,
            dia_pago,
            cantidad_pagos_semanales,
            factor_cobro,
            prestamo_personalizado: prestamo_personalizado || false,
            prestamo_personalizado_cantidad_pagos,
            prestamo_personalizado_periodicidad,
            prestamo_personalizado_interes,
            garantia,
            garantia_valor: garantia_valor || '',
            charge_factor_type: charge_factor_type || '',
            status
        }));
    }, [props.requisition]);

    const handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setState(prevState => ({
            ...prevState,
            [name]: value
        }));

        return true;
    };

    const handleChangeDate = (date) => {
        if (!_.isNull(date)) {
            setState(prevState => ({
                ...prevState,
                dia_pago: date.getDay(),
                fecha_elaboracion: date
            }));
        } else {
            setState(prevState => ({
                ...prevState,
                dia_pago: undefined,
                fecha_elaboracion: undefined
            }));
        }
    };

    const handleChargeFactor = (event) => {
        const target = event.target;
        const value = parseFloat(target.value);
        let cantidad_pagos_semanales = 0;
        if (state.prestamo_personalizado) {
            cantidad_pagos_semanales = RequisitionModel[RequisitionModel.setChargeFactorType(state.prestamo_personalizado_periodicidad, state.charge_factor_type, state.garantia, true)][value].cantidad_pagos_semanales;
        } else {
            cantidad_pagos_semanales = RequisitionModel[RequisitionModel.setChargeFactorType(state.prestamo_personalizado_periodicidad, state.charge_factor_type, state.garantia)][value].cantidad_pagos_semanales;
        }

        setState(prevState => ({
            ...prevState,
            charge_factor_type: RequisitionModel.setChargeFactorType(),
            factor_cobro: value,
            cantidad_pagos_semanales
        }));
        return true;
    };

    const handleForm = (event) => {
        if (event) event.preventDefault();

        if (state.status === 'autorizacion') {
            alert('La solicitud debe estar autorizada para poder generar pagos.');
            return;
        }

        if (!user.rol.es_administrador && state.prestamo_personalizado) {
            alert('Lo sentimos, no tiene autorización para editar el préstamo. Contacte al administrador para realizar cambios.');
            return;
        }

        let {
            fecha_elaboracion,
            dia_pago,
            cantidad_pagos_semanales,
            factor_cobro,
            charge_factor_type,
            prestamo_personalizado,
            prestamo_personalizado_cantidad_pagos,
            prestamo_personalizado_periodicidad,
            prestamo_personalizado_interes,
            garantia,
            garantia_valor
        } = state;

        const toDay = new Date();
        fecha_elaboracion.setHours(toDay.getHours());
        fecha_elaboracion.setMinutes(toDay.getMinutes());
        if (_.isEmpty(dia_pago)) {
            dia_pago = parseInt(fecha_elaboracion.getDay());
        } else {
            dia_pago = parseInt(dia_pago);
        }

        let fechaFinal = RequisitionModel.getLastPaymentDay(fecha_elaboracion, state.prestamo_personalizado, state.prestamo_personalizado_cantidad_pagos, state.prestamo_personalizado_periodicidad, state.cantidad_pagos_semanales, 'iso', false);
        fechaFinal = {
            __type: 'Date',
            iso: fechaFinal.toISOString()
        };

        fecha_elaboracion = {
            __type: 'Date',
            iso: fecha_elaboracion.toISOString()
        };
        charge_factor_type = RequisitionModel.setChargeFactorType(prestamo_personalizado_periodicidad, state.charge_factor_type, garantia, prestamo_personalizado);
        cantidad_pagos_semanales = parseInt(cantidad_pagos_semanales);
        
        factor_cobro = RequisitionModel[charge_factor_type][factor_cobro] ? RequisitionModel[charge_factor_type][factor_cobro].charge_factor : factor_cobro;

        prestamo_personalizado_cantidad_pagos = parseInt(prestamo_personalizado_cantidad_pagos);
        prestamo_personalizado_periodicidad = parseInt(prestamo_personalizado_periodicidad);
        prestamo_personalizado_interes = parseFloat(prestamo_personalizado_interes);

        let data = {
            dia_pago,
            cantidad_pagos_semanales: cantidad_pagos_semanales || { __op: 'Delete' },
            fecha_elaboracion,
            fecha_final_contrato: fechaFinal,
            factor_cobro: factor_cobro || { __op: 'Delete' },
            prestamo_personalizado: prestamo_personalizado === true || { __op: 'Delete' },
            prestamo_personalizado_cantidad_pagos: prestamo_personalizado_cantidad_pagos || { __op: 'Delete' },
            prestamo_personalizado_periodicidad: prestamo_personalizado_periodicidad || { __op: 'Delete' },
            prestamo_personalizado_interes: prestamo_personalizado_interes || { __op: 'Delete' },
            garantia: garantia || { __op: 'Delete' },
            garantia_valor: (!_.isEmpty(garantia) && garantia_valor) || { __op: 'Delete' },
            charge_factor_type: charge_factor_type || { __op: 'Delete' },
        };

        setState(prevState => ({
            ...prevState,
            loading: true
        }));

        Rest.peticion(`${config.api_url}/classes/Solicitudes/${props.id_solicitud}`, {
            method: 'PUT',
            body: JSON.stringify(data)
        }).then(res => {
            setState(prevState => ({
                ...prevState,
                loading: false
            }));
            return res.json();
        }).then(res => {
            return props.getRequisition();
        }).then(res => {
            if (res === true) {
                props.savePayments();
            } else {
                return false;
            }
        }).catch(error => {
            console.log(error);
        }).then(() => {
            setState(prevState => ({
                ...prevState,
                loading: false
            }));
        });
    };

    const handleChangeDirectState = (value, key) => {
        setState(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    if (state.status === 'baja') {
        return <div className="alert alert-info" role="alert">
            Este ciclo ha finalizado
        </div>;
    }

    return (
        <form className="card mt-3 mb-3" onSubmit={handleForm}>
            <Spinner loading={state.loading} />
            <fieldset className="card-body" disabled={!user.rol.es_administrador && state.prestamo_personalizado}>
                <div className="row">
                    <div className="col-md-4 mb-3 d-flex flex-column">
                        <label htmlFor="dia_pago">Fecha de elaboración <PopOver title="Fecha de elaboración" content="Se definirá el día de pago 7 días adelante." /></label>
                        
                        <DatePicker
                            placeholderText="Fecha de elaboración"
                            className="form-control"
                            locale="es"
                            selected={state.fecha_elaboracion}
                            dateFormat="dd/MM/yyyy"
                            onChange={date => handleChangeDate(date)}
                            minDate={new Date()}
                            showDisabledMonthNavigation
                            required
                            disabled={user.rol.es_administrador !== true && requisitions.inPaymentPeriod(props.requisition.status, fecha_elaboracion)}
                        />
                        
                        {user.rol.es_administrador !== true && requisitions.inPaymentPeriod(props.requisition.status, fecha_elaboracion) &&
                            <small className="form-text text-muted">Contacte al administrador para modificar este campo.</small>}
                        
                        {user.rol.es_administrador === true && requisitions.inPaymentPeriod(props.requisition.status, fecha_elaboracion) &&
                            <small className="form-text text-muted">Este campo permanecerá bloqueado 7 días después de la fecha establecida. Para modificar contácte al administrador.</small>}
                    </div>

                    <div className="col-12">
                        <ChargeFactor
                            handleChangeSolicitudData={handleChange}
                            handleChangeDirectState={handleChangeDirectState}
                            handleChargeFactor={handleChargeFactor}
                            factor_cobro={state.factor_cobro}
                            charge_factor_type={state.charge_factor_type}
                            cantidad_pagos_semanales={state.cantidad_pagos_semanales}
                            garantia={state.garantia}
                            garantia_valor={state.garantia_valor}
                            warranty={RequisitionModel.setRequiredWarranty()}
                            esMaster={RequisitionModel.esMaster}
                            ciclo={state.ciclo}
                            prestamo_personalizado={state.prestamo_personalizado}
                            prestamo_personalizado_periodicidad={state.prestamo_personalizado_periodicidad}
                            prestamo_personalizado_interes={state.prestamo_personalizado_interes}
                            prestamo_personalizado_cantidad_pagos={state.prestamo_personalizado_cantidad_pagos}
                            readOnly={false}
                        />
                    </div>
                </div>
                
                {(user.rol.es_administrador === true && <div className="row">
                    <div className="col-md-4 mb-3 mt-3">
                        <button type="submit" className="btn btn-primary btn-block">Generar Pagos</button>
                        <small className="form-text text-muted">Al modificar la fecha de elaboración y generar nuevamente los pagos, se reajustará la fecha de pagos a partir de dicha fecha</small>
                    </div>
                </div>)}
            </fieldset>
        </form>
    );
};

export default GroupRequisitionFastUpdate;