import React, { Component } from 'react';
import DocumentPaperLetter from './DocumentPaperLetter';
import Logo from './../../assets/static/logo-horizontal.svg';
import DocumentPaymentControlTable from './DocumentPaymentControlTable';
import RequisitionModel from '../../models/RequisitionModel';
import Parse from 'parse';
import _ from 'underscore';
import formatString from '../../utilities/format-string';

class DocumentPaymentControl extends Component {

    constructor (props) {
        super(props)
        // si no hay datos de sesión, redireccionar al login
        if (Parse.User.current()) {
            this.margin = this.getParamValue('no_margin');
            this.state = JSON.parse(localStorage.getItem('requisition'));
            if (!_.isEmpty(this.state)) {
                if (!_.isEmpty(this.state.fecha_elaboracion)) {
                    this.fecha = new Date(this.state.fecha_elaboracion.iso);
                    this.fecha.setHours(0);
                    this.fecha.setMinutes(0);
                    this.fecha.setMilliseconds(0);
                    this.fecha = this.fecha.getTime();    
                }
            }
        } else {
            window.location.href = '/';
        }
    }
    
    margin = '';

    getParamValue = (paramName) => {
        var url = window.location.search.substring(1); //get rid of "?" in querystring
        var qArray = url.split('&'); //get key-value pairs
        for (var i = 0; i < qArray.length; i++) 
        {
            var pArr = qArray[i].split('='); //split key and value
            if (pArr[0] === paramName) 
                return pArr[1]; //return value
        }
     }

    fecha = false;

    setAmountTotal = (amount_name) => {
        let sum = 0;
        _.each(this.state.montos, (item) => {
            if (item[amount_name] === '') {
                sum += 0;
            } else if (!_.isUndefined(item[amount_name])) {
                sum += parseInt(item[amount_name]);
            }
            
        });
        return sum;
    }

    setGroupTables = () => {
        let newRequests = [];
        let cantidadTablas = [];
        if (this.state.prestamo_personalizado_cantidad_pagos) {
            cantidadTablas.length = Math.ceil(this.state.prestamo_personalizado_cantidad_pagos / 5);
        } else {
            cantidadTablas.length = Math.ceil(this.state.cantidad_pagos_semanales / 5);
        }
        
        cantidadTablas.fill(this.state.montos);
        do{
            newRequests.push( cantidadTablas.splice(0, 2) );
        } while (cantidadTablas.length > 0);
        return newRequests;
    }

    setDateTime = (index) => {
        if (this.state.prestamo_personalizado_cantidad_pagos) {
            return this.fecha+ (1000*60*60*24*(index * this.state.prestamo_personalizado_cantidad_pagos * 4))
        } else {
            return this.fecha+ (1000*60*60*24*(index*7*4))
        }
        
    }

    setNumbersPayments = () => {
        let newNumWeekArray = [];
        let numeros = [];
        if (!_.isEmpty(this.state)) {
            if (this.state.prestamo_personalizado_cantidad_pagos) {
                for (let index = 0; index < this.state.prestamo_personalizado_cantidad_pagos; index++) {
                    numeros[index] = index + 1;
                }
                do{
                    newNumWeekArray.push( numeros.splice(0, 5) );
                } while (numeros.length > 0);
                numeros = [];
                do{
                    numeros.push( newNumWeekArray.splice(0, 2) );
                } while (newNumWeekArray.length > 0);
            } else {
                for (let index = 0; index < this.state.cantidad_pagos_semanales; index++) {
                    numeros[index] = index + 1;
                }
                do{
                    newNumWeekArray.push( numeros.splice(0, 5) );
                } while (numeros.length > 0);
                numeros = [];
                do{
                    numeros.push( newNumWeekArray.splice(0, 2) );
                } while (newNumWeekArray.length > 0);
            }
            
        }
        return numeros;
    }

    setPaymentDays = () => {
        let fechas = [];
        if (!_.isEmpty(this.state)) {
            fechas = RequisitionModel.setPaymentDays(this.fecha, this.state.prestamo_personalizado, this.state.prestamo_personalizado_cantidad_pagos, this.state.prestamo_personalizado_periodicidad, this.state.cantidad_pagos_semanales)
        }
        return fechas;
    }

    setPayday = (number_day) => {
        const days = [
            "Domingo",
            "Lunes",
            "Martes",
            "Miércoles",
            "Jueves",
            "Viernes",
            "Sábado"
        ];
        return days[number_day];
    }

    setPayText = (number) => {
        let text = '';
        switch (number) {
            case 7:
                text = 'SEMANAL';
                break;
            case 14:
                text = 'CATORCENAL';
                break;
            case 28:
                text = 'BICATORCENAL';
                break;                
            default:
                text = `${number} DÍAS REGULARES`;
                break;
        }
        return text;
    }

    setGarantiaValor = (type) => {
        let res = '';

        if (type === 'LIQUIDA') {
            res = new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( parseInt(this.state.garantia_valor) );
        } else {
            res = this.state.garantia_valor;
        }
        
        return res;
    }

    render() {
        return (
            <div className="w-100">
                {!_.isEmpty(this.state) && <DocumentPaperLetter className="d-flex flex-column" margin={this.margin}>
                    <div style={{...styles.docSection}} className="doc-section d-flex flex-column align-items-center justify-content-around pt-3">
                        <div className="text-center">
                            <img src={Logo} alt="SOLFIN DEL SURESTE | confía en tú palabra" width="380" />
                        </div>
                        
                        <h1 className="text-primary">CONTROL DE PAGOS</h1>
                        
                        <table className="table table-borderless m-0">
                            <tbody>
                                <tr className="d-flex">
                                    <th className="col-3 mr-0">SUCURSAL:</th>
                                    <td className="col-4">{this.state.Sucursal.nombre}</td>
                                    <th className="col-3 mr-0">MONTO DEL PRÉSTAMO:</th>
                                    <td className="col-2">{new Intl.NumberFormat('en',{style:'currency',currency:'USD'}).format( this.setAmountTotal('monto_autorizado') )}</td>
                                </tr>
                                <tr className="d-flex">
                                    <th className="col-3 mr-0">PROMOTOR DE CRÉDITO:</th>
                                    <td className="col-4">{this.state.promotor.nombre} {this.state.promotor.apellido_paterno} {this.state.promotor.apellido_materno}</td>
                                    <th className="col-3 mr-0">GARANTÍA:</th>
                                    <td className="col-2">{this.state.garantia || '--'}</td>
                                </tr>
                                <tr className="d-flex">
                                    <th className="col-3 mr-0">FECHA DE ELABORACIÓN:</th>
                                    <td className="col-4">{ formatString.setLegibleDate( new Date(this.setDateTime(0)).toISOString() ) }</td>
                                    <th className="col-3 mr-0">MONTO:</th>
                                    <td className="col-2">{this.setGarantiaValor(this.state.garantia) || '--'}</td>
                                </tr>
                                <tr className="d-flex">
                                    <th className="col-3 mr-0">NOMBRE DEL {`${this.state.Grupo.Socios.length === 1 ? 'CLIENTE' : 'GRUPO'}`}:</th>
                                    <td className="col-4">{this.state.Grupo.nombre}</td>
                                    <th className="col-3 mr-0">DÍA DE PAGO:</th>
                                    <td className="col-2 text-uppercase">{this.setPayday(this.state.dia_pago)}</td>
                                </tr>
                                <tr className="d-flex">
                                    <th className="col-3 mr-0">PLAZO:</th>
                                    <td className="col-4">{(this.state.prestamo_personalizado_periodicidad && this.setPayText(this.state.prestamo_personalizado_periodicidad)) || (this.state.cantidad_pagos_semanales && 'SEMANAL')}</td>
                                    <th className="col-3 mr-0">ID DE GRUPO:</th>
                                    <td className="col-2">{this.state.Grupo.id_en_sucursal}-{this.state.Grupo.Sucursal.sigla}</td>
                                </tr>
                                <tr className="d-flex">
                                    <th className="col-3 mr-0">PERIODICIDAD DE PAGO:</th>
                                    <td className="col-4">{(this.state.prestamo_personalizado_periodicidad && `${this.state.prestamo_personalizado_periodicidad} DÍAS`) || (this.state.cantidad_pagos_semanales && '7 DÍAS')}</td>
                                    <th className="col-3 mr-0">SOLICITUD:</th>
                                    <td className="col-2">{this.state.id_en_sucursal}</td>
                                </tr>
                                <tr className="d-flex">
                                    <th className="col-3 mr-0">NÚMERO DE PAGOS:</th>
                                    <td className="col-4">{this.state.prestamo_personalizado_cantidad_pagos ? this.state.prestamo_personalizado_cantidad_pagos : this.state.cantidad_pagos_semanales}</td>
                                    <th className="col-3 mr-0">CICLO:</th>
                                    <td className="col-2">{this.state.ciclo}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    
                    
                    {this.setGroupTables().map((groupTable, indexGroupTable) => <div style={{...styles.docSection}} className="doc-section d-flex flex-column align-items-center justify-content-around" key={indexGroupTable}>
                        {groupTable.map((amounts, indexAmount) => <DocumentPaymentControlTable 
                        key={indexAmount} 
                        index={indexAmount+1}
                        newNumWeekArray={this.setNumbersPayments()[indexGroupTable][indexAmount]} 
                        paymentDays={this.setPaymentDays()[indexGroupTable][indexAmount]} 
                        amounts={amounts} 
                        promoter={this.state.promotor} 
                        elaborationDate={this.setDateTime(indexAmount)}
                        requisition={this.state} />)}
                    </div>)}
                </DocumentPaperLetter>}
            </div>
        );
    }
}

export default DocumentPaymentControl;

const styles = {
    docSection: {
        height: (17.85+2.929)+'cm',
        // height: ((27.94+(2*2))/2)+'cm',
    },
    // marginTop: {
    //     marginTop: '2cm'
    // },
    // marginBottom: {
    //     marginBottom: '2cm'
    // }
}

