import React, { Component } from 'react';
import SelectBranchOfficeGroup from './SelectBranchOfficeGroup';
import GroupSearchPartner from './GroupSearchPartner';
import config from '../../settings/config';
import Rest from '../../Server/Rest';
import SelectEmpleyee from '../forms/SelectEmpleyee';
import Parse from 'parse';
import user from './../../settings/user';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import _ from 'underscore';
import RequisitionModel from '../../models/RequisitionModel';
import requisitions from '../../services/requisitions';
import { registerLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
import formatString from '../../utilities/format-string';
import { Link } from "react-router-dom";
import ChargeFactor from '../forms/ChargeFactor';
import PopOver from '../others/Popover';
import moment from 'moment';
registerLocale('es', es)

class GroupForm extends Component {
    constructor(props){
        super(props);

        const config = user.config();
        if (user.rol.es_administrador !== true) {
            this.filter_from = config && config.filter_from ? new Date(config.filter_from) : null;
        }

        this.state = this.state_init();
        
        this.handleChangeInputForm = this.handleChangeInputForm.bind(this);
        this.handleChangeClabe = this.handleChangeClabe.bind(this);
        this.handleChangeAmount = this.handleChangeAmount.bind(this);
        this.handleChargeFactor = this.handleChargeFactor.bind(this);
        this.handleChangeAval = this.handleChangeAval.bind(this);
        this.handleChangeBanckPartner = this.handleChangeBanckPartner.bind(this);
    }

    fecha_elaboracion = null;
    filter_from = null;
    
    state_init = () => {
        const d = new Date();

        this.partners =  [];
        this.partnersSelected =  [];
        this.groupId =  '';
        this.diccionarioId = '';
        this.info_url_responsable =  '';
        this.fecha_registro =  d.getDate() +'/'+ (d.getMonth()+1) +'/'+ d.getFullYear();
        this.requisitions =  [];
        this.current_requisition =  {};
        this.activeNewCycle =  true;

        return _.extend({
            ciclo: 1,
            Sucursal: Parse.User.current().toJSON().Sucursal ? Parse.User.current().toJSON().Sucursal.objectId : '', // Pointer
            Socios: [], // Pointers
            nombre: '',
            promotor: '', // Pointer
            montos: [], // Pointers
            responsable: '',
            responsable_tdd_banco: '',
            responsable_tdd_numero: '',
            responsable_tdd_titular: '',
            responsable_clabe_banco: '',
            responsable_clabe: '',
            responsable_clabe_titular: '',
            modo_desembolso: '',
            status: '',
            status_comentario: '',
            grupo_reducido: false,
            grupo: {
                status: ''
            },
            aval: {
                nombre: '',
                apellido_paterno: '',
                apellido_materno: '',
                domicilio_direccion: '',
                domicilio_cp: '',
                domicilio_entidad: '',
                domicilio_poblacion: '',
                domicilio_colonia: ''
            },

            cuentas_bancarias: [{
                Socio: {
                    __type: 'Pointer',
                    className: 'Socios',
                    objectId: ''
                },
                tipo: '',
                banco: '',
                cuenta: '',
                titular: ''
            }]
        }, this.requisition_init());
    };

    requisition_init = () => {
        return {
            fecha_elaboracion: '',
            dia_pago: '',
            cantidad_pagos_semanales: 0,
            factor_cobro: 0,
            charge_factor_type: '',
            prestamo_personalizado: false,
            prestamo_personalizado_cantidad_pagos: 0,
            prestamo_personalizado_periodicidad: 0,
            prestamo_personalizado_interes: 0,
            garantia: '',
            garantia_valor: '',
            status: 'autorizacion'
        }
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        // para resetear el formulario
        if (nextProps.resetform === true) {
            this.setState(this.state_init());
        }

        // sirve para la edición de socios
        if (!_.isEmpty(nextProps.data) && nextProps.data.objectId) {
            this.activeNewCycle = false;
            // Establecemos la fecha de registro
            this.setRegisterDate(nextProps.data.fecha_registro.iso);
            this.groupId = nextProps.data.objectId;
            this.setUrlResponsable(nextProps.data.responsable.objectId);
            this.requisitions = nextProps.data.Solicitudes;
            this.current_requisition = this.requisitions[this.requisitions.length -1];
            _.each(nextProps.data.Socios, socio => this.handleAddPartner(socio));
            let state = this.setDataUpdateState(nextProps.data);
            state.grupo = nextProps.data;
            state.cuentas_bancarias = this.requisitions[this.requisitions.length -1].cuentas_bancarias || [{
                Socio: {
                    __type: 'Pointer',
                    className: 'Socios',
                    objectId: ''
                },
                tipo: '',
                banco: '',
                cuenta: '',
                titular: ''
            }];
            this.setState(state);
        }
    }

    UNSAFE_componentWillUpdate = (nextProps, nextState) => {
        nextProps.setData(nextState);
    }

    setRegisterDate = (fecha) => {
        const d = new Date(fecha);
        this.fecha_registro = formatString.setLegibleDate(d.toISOString());
    }

    setAmount = (Solicitudes = false) => {
        if (Solicitudes[Solicitudes.length -1].montos.length === this.partnersSelected.length) {
            return Solicitudes[Solicitudes.length -1].montos;
        } else {
            const montos = _.map(this.partnersSelected, (monto, index) => {
                if (!_.isEmpty(Solicitudes[Solicitudes.length -1].montos[index])) {
                    return Solicitudes[Solicitudes.length -1].montos[index];
                } else {
                    return {
                        Socio: {
                            __type: 'Pointer',
                            className: 'Socios',
                            objectId: this.partnersSelected[index].objectId
                        },
                        monto_actual: 0,
                        monto_solicitado: 0,
                        monto_sugerido: 0,
                        monto_autorizado: 0
                    }
                }
            });
            return montos;
        }
        
    }

    setPayday = (number_day) => {
        const days = [
            "Domingo",
            "Lunes",
            "Martes",
            "Miércoles",
            "Jueves",
            "Viernes",
            "Sábado"
        ];
        return days[number_day];
    }

    setCreditCard = (credit_card) => {
        let element = '';
        for (let index = 0; index < credit_card.length; index++) {
            if (index === 3 || index === 7 || index === 11) 
                element += credit_card[index]+' ';
            else 
                element += credit_card[index];
            
        }
        return element;
    }

    setDataUpdateState = (data = false) => {
        let state = _.clone(this.state);
        if (data !== false) {
            
            _.map(state, (value, key) => {
                switch (key) {
                    case 'promotor':
                    case 'responsable':
                    case 'Sucursal':
                        state[key] = data[key].objectId;
                        break;
                    case 'fecha_registro':
                        break;
                    case 'Socios':
                        this.partnersSelected = data[key];
                        state[key] = _.map(data[key], socio => {
                            return socio.objectId;
                        });
                        break;
                    case 'montos':
                        state[key] = this.setAmount(data['Solicitudes']);
                        break;
                    case 'ciclo':
                    case 'dia_pago':
                    case 'cantidad_pagos_semanales':
                    case 'prestamo_personalizado':
                    case 'prestamo_personalizado_cantidad_pagos':
                    case 'prestamo_personalizado_periodicidad':
                    case 'prestamo_personalizado_interes':
                        state[key] = data['Solicitudes'][data['Solicitudes'].length -1][key];
                        break;
                    case 'garantia':
                    case 'garantia_valor':
                        state[key] = data['Solicitudes'][data['Solicitudes'].length -1][key] || '';
                        break;
                    case 'fecha_elaboracion':
                        const fecha_elaboracion = data['Solicitudes'][data['Solicitudes'].length -1][key];
                        if (!_.isEmpty(fecha_elaboracion)) {
                            state['fecha_elaboracion'] = new Date(fecha_elaboracion.iso);
                            this.fecha_elaboracion = new Date(fecha_elaboracion.iso);
                        }
                        break;
                    case 'factor_cobro':
                        const factor_cobro = data['Solicitudes'][data['Solicitudes'].length -1][key];
                        if (factor_cobro) {
                            state[key] = factor_cobro;
                        }
                        break;
                    default:
                        state[key] = data[key] || this.state[key];
                        break;
                }
            })
        }
        
        return state;
    }

    setText = (value) => {
        let text = '';
        switch (value) {
            case 'autorizacion':
                text = 'Autorización';
                break;
            case 'autorizado':
                text = 'Autorizado';
                break;
            case 'rechazado':
                text = 'Rechazado';
                break;
            case 'baja':
                text = 'Baja';
                break;
            case 'baja_voluntaria':
                text = 'Baja voluntaria';
                break;
            case 'reestructurado':
                text = 'Reestructurado';
                break;
            case 'morosidad':
                text = 'Morosidad';
                break;
            case 'PRENDARIA':
                text = 'PRENDARIA';
                break;
            case 'LIQUIDA':
                text = 'LÍQUIDA';
                break;
            default:
                text = value;
                break;
        }
        return text;
    }

    handleChangeInputForm = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
        
        return true;
    }

    handleChangeDirectState = (value, key) => {
        this.setState({
            [key]: value
        });
    }

    handleChangeBanckPartner = (event, index) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        const { cuentas_bancarias } = this.state;

        switch (name) {
            case 'socio':
                cuentas_bancarias[index].Socio.objectId = value;
                break;
            case 'cuenta':
                if (/^[0-9|(\s)]*$/.test(value))
                    cuentas_bancarias[index][name] = value;
                break;
            default:
                cuentas_bancarias[index][name] = value;
                break;
        }
        
        this.setState({cuentas_bancarias});
        
        return true;
    }

    handleChangeClabe = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        if (/^[0-9]{0,18}$/.test(value)) {
            
            this.setState({
                [name]: value
            });
        }

        
        return true;
    }

    handleChangeAval = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        let {aval} = this.state;

        switch (name) {
            case 'aval_nombre':
                aval.nombre = value;
                break;
            case 'aval_apellido_paterno':
                aval.apellido_paterno = value;
                break;
            case 'aval_apellido_materno':
                aval.apellido_materno = value;
                break;
            case 'aval_domicilio_direccion':
                aval.domicilio_direccion = value;
                break;
            case 'aval_domicilio_cp':
                aval.domicilio_cp = value;
                break;
            case 'aval_domicilio_entidad':
                aval.domicilio_entidad = value;
                break;
            case 'aval_domicilio_poblacion':
                aval.domicilio_poblacion = value;
                break;
            case 'aval_domicilio_colonia':
                aval.domicilio_colonia = value;
                break;
        }

        this.setState({
            aval: aval
        });
        
        return true;
    }

    handleChargeFactor  = (event) => {
        const target = event.target;
        const value = parseFloat(target.value);

        let cantidad_pagos_semanales = 0;
        if (this.state.prestamo_personalizado) {
            cantidad_pagos_semanales = RequisitionModel[RequisitionModel.setChargeFactorType(this.state.prestamo_personalizado_periodicidad, this.state.charge_factor_type, this.state.garantia, true)][value].cantidad_pagos_semanales;
        } else {
            cantidad_pagos_semanales = RequisitionModel[RequisitionModel.setChargeFactorType(this.state.prestamo_personalizado_periodicidad, this.state.charge_factor_type, this.state.garantia)][value].cantidad_pagos_semanales;
        }

        this.setState({
            charge_factor_type: RequisitionModel.setChargeFactorType(this.state.prestamo_personalizado_periodicidad, this.state.charge_factor_type, this.state.garantia, this.state.prestamo_personalizado),
            factor_cobro: value,
            cantidad_pagos_semanales: cantidad_pagos_semanales
        });
        
        return true;
    }

    handleChangeAmount = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const name_split = name.split(',');
        
        let {montos} = this.state;
        if (value <= 0 && value !== '') {
            montos[name_split[1]][name_split[0]] = 0;
        } else {
            montos[name_split[1]][name_split[0]] = value;    
        }
        

        this.setState({
            montos: montos
        });
    }

    setAmountTotal = (amount_name) => {
        
        const sum = RequisitionModel.setAmountTotal_0(amount_name, this.state.montos);

        return new Intl.NumberFormat('en').format(sum);

    }

    setUrlResponsable = (partnerId) => {
        
        let params;
        
        let where = JSON.stringify({
            Socio: {
                __type: 'Pointer',
                className: 'Socios',
                objectId: partnerId
            }
        });

        params = new URLSearchParams({
            where: where,
            count: 1,
            include: 'Socios,Socio.lugar_registro'
        });
        Rest.peticion(`${config.api_url}/classes/SociosDiccionario?${params.toString()}`, {
            method: 'GET'
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            this.info_url_responsable = `/clientas/${res.results[0].objectId}/${res.results[0].Socio.objectId}/${res.results[0].Socio.id_en_sucursal}/datos-personales`;
        })
        .catch(error => {
            console.log(error);
        });
    }

    setResponsable = (partnerId) => {
        if (!_.isEmpty(partnerId) && this.partnersSelected.length) {
            const partner = _.findWhere(this.partnersSelected, {objectId: partnerId});
            if (!_.isEmpty(partner)) {
                return partner.nombre + ' ' +partner.apellido_paterno + ' ' + partner.apellido_materno;
            } else {
                return '';   
            }
        } else {
            return '';
        }

    }

    setDisabledNewCicle = () => {
        let status = false;

        // Desactivar en los siguientes casos:
        /**
         * cuando el ciclo se cierre con status de morosidad en el ciclo 1
         * cuando la solicitud no está en baja y el grupo no está activo
         * cuando la solicitud es activa y el grupo activo
         * cuando haya pasado 6 meses o mas del ultimo pago
         */
        
        //PENDIENTE - checar cantidad_pagos_semanales en grupales | prestamo_personalizado_cantidad_pagos en individuales
        let fechaFinal = RequisitionModel.getLastPaymentDay(this.fecha_elaboracion, this.state.prestamo_personalizado, this.state.prestamo_personalizado_cantidad_pagos, this.state.prestamo_personalizado_periodicidad, this.state.cantidad_pagos_semanales, 'iso', false);

        fechaFinal = new Date(fechaFinal);
        const now = new Date();
        const diff = now.getTime() - fechaFinal.getTime();
        const daysdiff = Math.round(diff/(24*60*60*1000));

        if (!_.isEmpty(this.requisitions)) {
            if (
                (
                    (
                        this.requisitions.length === 1 &&
                        this.requisitions[0].status === 'morosidad'
                    ) ||
                    (
                        this.requisitions[this.requisitions.length -1].status !== 'baja' &&
                        this.state.grupo.status !== 'activo'
                    ) ||
                    (
                        this.requisitions[this.requisitions.length -1].status === 'activo' &&
                        this.state.grupo.status === 'activo'
                    ) 
                    || (daysdiff >= 180 && !this.requisitions[this.requisitions.length -1].prestamo_personalizado_interes)
                )
            ) {
                status = true;
            }
        }

        return status;
    }

    setfactor_cobro = (value) => {

        let text = '';
        switch (value) {
            case 135:
                text = '$135 (10 semanas)';
                break;
            case 129:
                text = '$129 (10 semanas)';
                break;
            case 126.5:
                text = '$126.5 (10 semanas)';
                break;
            case 116:
                text = '$116 (12 semanas)';
                break;
            case 113:
                text = '$113 (12 semanas)';
                break;
            case 110:
                text = '$110 (12 semanas)';
                break;
            case 105:
                text = '$105 (14 semanas)';
                break;
            case 101:
                text = '$101 (14 semanas)';
                break;
            case 96.5:
                text = '$96.5 (14 semanas)';
                break;
            case 95:
                text = '$95 (16 semanas)';
                break;    
            case 92.5:
                text = '$92.5 (16 semanas)';
                break;
            case 89.5:
                text = '$89.5 (16 semanas)';
                break;
            case 270:
                text = '$270 (5 catorcenas)';
                break;
            case 260:
                text = '$260 (5 catorcenas)';
                break;
            case 254:
                text = '$254 (5 catorcenas)';
                break;
            case 232:
                text = '$232 (6 catorcenas)';
                break;
            case 226:
                text = '$226 (6 catorcenas)';
                break;
            case 220:
                text = '$220 (6 catorcenas)';
                break;
            case 210:
                text = '$210 (7 catorcenas)';
                break;
            case 202:
                text = '$202 (7 catorcenas)';
                break;
            case 194:
                text = '$194 (7 catorcenas)';
                break;
            case 190:
                text = '$190 (8 catorcenas)';
                break;
            case 186:
                text = '$186 (8 catorcenas)';
                break;
            case 180:
                text = '$180 (8 catorcenas)';
                break;
            default:
                text = 'Sin especificar';
                break;
        }
        return text;

    }

    setAmountTotal_0 = RequisitionModel.setAmountTotal_0;

    esMaster = RequisitionModel.esMaster;

    setRequiredWarranty = RequisitionModel.setRequiredWarranty;

    openPartnerInfoResponsable = () => {
        window.open(this.info_url_responsable, '_blank');
    }

    handleAddPartner = (partner) => {
        let {state} = this;
        if (_.isEmpty(_.findWhere(this.partnersSelected, {objectId: partner.objectId}))) {
            this.partnersSelected.push(_.clone(partner));
            state.Socios.push(partner.objectId);
            state.montos.push({
                Socio: {
                    __type: 'Pointer',
                    className: 'Socios',
                    objectId: partner.objectId
                },
                monto_actual: 0,
                monto_solicitado: 0,
                monto_sugerido: 0,
                monto_autorizado: 0
            });
            this.setState({
                Socios: state.Socios,
                montos: state.montos
            });
        } else {
            alert('La clienta ya fue agregada.');
        }
    }

    handleRemovePartner = (param, index) => {

        let {state} = this;
        this.partnersSelected = _.reject(this.partnersSelected, partner => {
            return param.objectId === partner.objectId
        });
        state.Socios = _.without(state.Socios, param.objectId);
        state.montos = _.without(state.montos, state.montos[index]);
        this.setState({
            Socios: state.Socios,
            montos: state.montos
        });

    }

    setMontoActual = (partner, previous_requisition) => {
        let res = 0;
        const _amount = _.find(previous_requisition.montos, monto => {
            return _.isEqual(monto.Socio, {__type:"Pointer",className:"Socios",objectId:partner.objectId})
        });
        if (!_.isEmpty(_amount)) {
            res = _amount.monto_autorizado
        }
        return res;
    }

    handleChangeDate = (date) => {
        this.setState({
            dia_pago: date.getDay(),
            fecha_elaboracion: date
        })
    }
    
    handleNewCycle = () => {
        if (this.activeNewCycle === true) {
            return;
        }
        let ciclo = this.state.ciclo+1;
        this.activeNewCycle = true;
        this.props.setActiveNewCycle(this.activeNewCycle);
        this.current_requisition = {};
        const previous_requisition = _.findWhere(_.clone(this.requisitions.reverse()), {status: 'baja'});
        if (!_.isEmpty(previous_requisition)) {

            

            let newState = _.extend({
                montos: _.map(this.partnersSelected, (partner, index) => {
                    return {
                        Socio: {
                            __type: 'Pointer',
                            className: 'Socios',
                            objectId: partner.objectId
                        },
                        monto_actual: this.setMontoActual(partner, previous_requisition),
                        monto_solicitado: 0,
                        monto_sugerido: 0,
                        monto_autorizado: 0
                    }
                }),
                ciclo: ciclo,
                dia_pago: '',
                cantidad_pagos_semanales: 10,
                factor_cobro: 0
            }, this.requisition_init());

            this.setState(state => (newState));
        } else {
            let newState = _.extend({
                montos: _.map(this.state.montos, monto => {
                    return {
                        Socio: {
                            __type: 'Pointer',
                            className: 'Socios',
                            objectId: monto.Socio.objectId
                        },
                        monto_actual: monto.monto_autorizado,
                        monto_solicitado: 0,
                        monto_sugerido: 0,
                        monto_autorizado: 0
                    }
                }),
                ciclo: ciclo,
                dia_pago: '',
                cantidad_pagos_semanales: 10
            }, this.requisition_init());
            this.setState(state => (newState));
        }
        
    }

    handleCancelNewCycle = () => {
        this.activeNewCycle = false;
        this.props.setActiveNewCycle(this.activeNewCycle);
        this.props.getGroup();
    };

    setRemovePartner = (partner, index) => {
        if (_.findWhere(this.props.partnersStatics, {objectId: partner.objectId})) {
            this.props.setRemovePartner(partner);
        } else {
            this.handleRemovePartner(partner, index);
        }
    }

    setBtnRemovePartner = (index) => {
        let result;
        if(_.isEmpty(this.groupId)) {
            result = <button type="button" className="btn btn-link text-danger" tabIndex="-1" onClick={() => this.handleRemovePartner(this.partnersSelected[index], index)} title="Quitar">
                <i className="fas fa-user-minus"></i>
            </button>
        } else if (this.state.status === 'autorizado' && user.rol.permisos.Grupos && user.rol.permisos.Grupos.quitar_clientas) {
            result = <button type="button" className="btn btn-link text-danger" tabIndex="-1" onClick={() => {this.setRemovePartner(this.partnersSelected[index], index)}} title="Quitar del grupo">
                <i className="fas fa-user-minus"></i>
            </button>
        } else if (this.state.status === 'autorizado') {
            result = <button type="button" className="btn btn-link text-danger" tabIndex="-1" onClick={() => alert('Solo el Administrador o Gerente pueden quitar la clienta del grupo con solicitud autorizada.')} title="Quitar del grupo">
                <i className="fas fa-user-minus"></i>
            </button>
        } else if (this.state.status !== 'autorizado') {
            result = <button type="button" className="btn btn-link text-danger" tabIndex="-1" onClick={() => {this.setRemovePartner(this.partnersSelected[index], index)}} title="Quitar del grupo">
                <i className="fas fa-user-minus"></i>
            </button>
        }
        
        return result;
    }

    addItem = (preIndex) => {
        const coll = this.state.cuentas_bancarias;
        const newColl = [];
        for (let index = 0; index < coll.length; index++) {
            newColl.push(coll[index]);
            if (index === preIndex) {
                newColl[index+1] = {
                    Socio: {
                        __type: 'Pointer',
                        className: 'Socios',
                        objectId: ''
                    },
                    tipo: '',
                    banco: '',
                    cuenta: '',
                    titular: ''
                };
            }
        }
        this.setState({cuentas_bancarias: newColl});
    }

    removeItem = (index) => {
        const coll = this.state.cuentas_bancarias;
        if (!(coll.length === 1)) {
            coll.splice(index, 1)
            this.setState({cuentas_bancarias: coll});
        } else {
            this.setState({cuentas_bancarias: [{
                Socio: {
                    __type: 'Pointer',
                    className: 'Socios',
                    objectId: ''
                },
                tipo: '',
                banco: '',
                cuenta: '',
                titular: ''
            }]});
        }
    }

    handleSetPay = (index) => {
        const pay = RequisitionModel.setPay(RequisitionModel.formatData(this.state), parseInt(this.state.montos[index].monto_sugerido));
        return formatString.setLegiblePrice(pay);
    }

    render() {
        return (
            <>
                <fieldset className="form-group card">
                    <div className="card-body">
                        <legend>Datos del generales</legend>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="form-group">
                                {
                                    !this.props.readOnly && <>
                                        <label>Fecha de registro</label>
                                        <span className="form-control text-muted">{this.fecha_registro}</span>
                                    </> || <>
                                        <small className="text-muted">Fecha de registro</small>
                                        <p>
                                            {this.fecha_registro}
                                        </p>
                                    </>
                                }
                                </div>
                            </div>
                            {!_.isEmpty(this.state.grupo) && !_.isEmpty(this.state.grupo.status) && <div className="col-md-4">
                                {
                                    !this.props.readOnly && <>
                                        <label>Status del grupo</label>
                                        <div className="form-group">
                                            {(
                                                !(user.rol.permisos.Creditos && user.rol.permisos.Creditos.cambiar_status !== true) && 
                                                <span className="form-control text-muted text-uppercase">{this.setText(this.state.status)}</span>)
                                                || 
                                                (
                                                    this.state.grupo.status !== 'autorizacion' && <select className="form-control" name="status" value={this.state.status} onChange={this.handleChangeInputForm} required>
                                                        <option value="">STATUS</option>
                                                        <option value="activo" disabled>ACTIVO</option> {/* no está activa la opción porque hay un botón visible para activar el grupo */}
                                                        <option value="autorizacion">AUTORIZACIÓN</option>
                                                        <option value="autorizado">AUTORIZADO</option>
                                                        <option value="baja">BAJA</option>
                                                        <option value="morosidad">MOROSIDAD</option>
                                                        <option value="rechazado">RECHAZADO</option>
                                                    </select>
                                                )
                                            }
                                        </div>
                                    </> || <>
                                        <small className="text-muted">Status del grupo</small>
                                        <p className="text-uppercase">{this.setText(this.state.status)}</p>
                                    </>
                                }
                                      
                            </div>}
                            {!this.props.readOnly && ((this.state.grupo.status === 'baja' || this.state.grupo.status === 'rechazado' || this.state.grupo.status === 'morosidad') && user.rol.permisos.Grupos && user.rol.permisos.Grupos.activar) && <div className="col-md-4 align-self-end">
                                <button type="button" className="btn btn-secondary mb-3" onClick={() => this.props.handleActiveGroup()}>Activar grupo</button>
                            </div>}
                            <div className="col-12"></div>
                            <div className="col-md-4">
                                <div className="form-group">
                                {
                                    !this.props.readOnly && <>
                                        <label htmlFor="nombre">Nombre del grupo</label>
                                        <input type="text" className="form-control text-uppercase" id="nombre" name="nombre" value={this.state.nombre} onChange={this.handleChangeInputForm} onBlur={() => this.props.handleValidName()} required={this.props.required} disabled={!_.isEmpty(this.groupId) && !(user.rol.es_administrador === true)} />
                                    </> || <>
                                        <small className="text-muted">Nombre del grupo</small>
                                        <p>
                                            {this.state.nombre}
                                        </p>
                                    </>
                                }
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    
                                    {!this.props.readOnly && <label htmlFor="Sucursal">
                                        Sucursal
                                    </label> || 
                                    <small className="text-muted">
                                        Sucursal
                                    </small>}
                                    {(!this.props.readOnly && user.rol.es_administrador) && <SelectBranchOfficeGroup name="Sucursal" id="Sucursal" resetform={this.props.resetform} readOnly={this.props.readOnly} handleValidName={this.props.handleValidName} value={this.state.Sucursal} defaultValue={this.state.Sucursal} onChange={this.handleChangeInputForm} required={true} />}
                                    {(!this.props.readOnly && !user.rol.es_administrador) && <SelectBranchOfficeGroup disabled={!user.rol.es_administrador} name="Sucursal" id="Sucursal" resetform={this.props.resetform} readOnly={this.props.readOnly} handleValidName={this.props.handleValidName} value={this.state.Sucursal} defaultValue={Parse.User.current().toJSON().Sucursal.objectId} onChange={this.handleChangeInputForm} required={true} />}
                                    {this.props.readOnly && <SelectBranchOfficeGroup name="Sucursal" id="Sucursal" resetform={this.props.resetform} readOnly={this.props.readOnly} handleValidName={this.props.handleValidName} value={this.state.Sucursal} defaultValue={this.state.Sucursal} onChange={this.handleChangeInputForm} required={true} />}
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    {!this.props.readOnly && <label htmlFor="promotor">
                                        Promotor del grupo
                                    </label> || 
                                    <small className="text-muted">
                                        Promotor del grupo
                                    </small>}
                                    <SelectEmpleyee readOnly={this.props.readOnly} name="promotor" id="promotor" value={this.state.promotor} onChange={this.handleChangeInputForm} required={this.props.required} />
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
                <fieldset className="form-group card">
                    <div className="card-body">
                        
                        <legend>Clientas del grupo y Montos</legend>
                        
                        <div className="row">
                            <div className="col-md-6">
                                {!this.props.readOnly && <>
                                    <div className="position-relative">
                                        <div className="form-group position-relative" style={{zIndex: 1}}>
                                            <label>Buscar clientas</label>
                                            <GroupSearchPartner
                                            handleAddPartner={this.handleAddPartner}/>
                                        </div>
                                    </div>
                                </>}
                            </div>
                            {!this.props.readOnly && !_.isEmpty(this.props.id_grupo) && <div className="col-md-6">
                                {
                                    (
                                        !_.isEmpty(this.groupId) || 
                                        ((!_.isEmpty(this.groupId) && this.activeNewCycle === false)) || 
                                        this.activeNewCycle === true
                                    ) &&
                                    <><label>Opciones</label><br/></>
                                }
                                <div className="btn-group d-flex" role="group" aria-label="Opciones">
                                    {
                                        // forma antigüa de crear ciclos
                                        !(!_.isEmpty(user.rol.permisos.Grupos) && user.rol.permisos.Grupos.wizard) &&
                                        <>
                                            {!_.isEmpty(this.groupId) && <button type="button" className="btn btn-success w-50" onClick={() => this.handleNewCycle()} disabled={this.setDisabledNewCicle() || this.activeNewCycle === true}>Nueva solicitud</button>}
                                            {<button type="button" className="btn btn-secondary w-50" onClick={() => this.handleCancelNewCycle()} disabled={this.setDisabledNewCicle() || this.activeNewCycle === false}>Cancelar</button>}
                                        </>
                                    }
                                    
                                    {
                                        // flujo nuevo de creación de ciclos
                                        (!_.isEmpty(user.rol.permisos.Grupos) && user.rol.permisos.Grupos.wizard) &&
                                        <>
                                            {((this.setDisabledNewCicle() || this.activeNewCycle === true)
                                            && <span className="btn btn-success w-50 disabled mt-3">Nueva solicitud</span>)
                                            || <Link to={`/nuevo-ciclo/${this.props.id_diccionario}/${this.groupId}`} title="Nueva solicitud" className="btn btn-success w-50 mt-3">Nueva solicitud</Link>}
                                        </>
                                    }
                                </div>
                            </div>}
                        </div>
                        
                        <br />
                        
                        {(true) && <div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group d-flex flex-column">
                                    {
                                        (!this.props.readOnly && <>
                                            <label htmlFor="dia_pago">Fecha de elaboración</label>
                                            {
                                                (this.state.status === 'autorizado' && 
                                                <span className="form-control text-muted">
                                                    {formatString.setLegibleDate(this.state.fecha_elaboracion.toISOString())}
                                                </span>) || <>
                                                    {(this.state.status === 'autorizacion' && <DatePicker
                                                    placeholderText="Fecha de elaboración"
                                                    className="form-control"
                                                    locale="es"
                                                    selected={this.state.fecha_elaboracion}
                                                    dateFormat="dd/MM/yyyy"
                                                    onChange={date => this.handleChangeDate(date)}
                                                    minDate={new Date()}
                                                    showDisabledMonthNavigation
                                                    required
                                                    />) || <span className="form-control text-muted">{formatString.setLegibleDate(this.state.fecha_elaboracion.toISOString())}</span>}
                                                    <small className="form-text text-muted">Se definirá el día de pago 7 días adelante.</small>
                                                    {user.rol.es_administrador !== true && requisitions.inPaymentPeriod(this.state.status, this.fecha_elaboracion) && 
                                                    <small className="form-text text-muted">Contacte al administrador para modificar este campo.</small>}
                                                    {user.rol.es_administrador === true && requisitions.inPaymentPeriod(this.state.status, this.fecha_elaboracion) && 
                                                    <small className="form-text text-muted">Este campo permanecerá bloqueado 7 días después de la fecha establecida. Para modificar contácte al administrador.</small>}
                                                </>
                                            }
                                        </>) || <>
                                            <small className="text-muted">Fecha de elaboración</small>
                                            <p className="text-uppercase">
                                                {this.setPayday(this.state.dia_pago)} {this.state.fecha_elaboracion && <>({formatString.setLegibleDate(this.state.fecha_elaboracion.toISOString())})</>}
                                            </p>
                                        </>
                                    }
                                    </div>
                                </div>
                                
                                <div className="col-md-4">
                                    <div className="form-group">
                                    {
                                        !this.props.readOnly && <>
                                            <label>Ciclo</label>
                                            <span className="form-control text-muted">{this.state.ciclo}</span>
                                        </> || <>
                                            {
                                                !_.isEmpty(this.groupId) && 
                                                <small className="text-muted">Ciclo actual</small> ||
                                                <small className="text-muted">Ciclo</small>
                                            }
                                            
                                            <p>
                                                {this.state.ciclo}
                                            </p>
                                        </>
                                    }
                                    </div>
                                </div>
                            </div>

                            <ChargeFactor
                            handleChangeSolicitudData={this.handleChangeInputForm}
                            handleChangeDirectState={this.handleChangeDirectState}
                            handleChargeFactor={this.handleChargeFactor}
                            factor_cobro={this.state.factor_cobro}
                            charge_factor_type={this.current_requisition.charge_factor_type}
                            cantidad_pagos_semanales={this.current_requisition.cantidad_pagos_semanales}
                            garantia={this.state.garantia}
                            garantia_valor={this.state.garantia_valor}
                            warranty={this.setRequiredWarranty()}
                            esMaster={this.esMaster}
                            ciclo={this.state.ciclo}
                            prestamo_personalizado={this.state.prestamo_personalizado}
                            prestamo_personalizado_periodicidad={this.state.prestamo_personalizado_periodicidad}
                            prestamo_personalizado_interes={this.state.prestamo_personalizado_interes}
                            prestamo_personalizado_cantidad_pagos={this.state.prestamo_personalizado_cantidad_pagos}
                            readOnly={this.props.readOnly}
                            disabled={this.state.status !== 'autorizacion'}
                            />

                            <div className="row">
                                {!_.isEmpty(this.current_requisition.status) && <div className="form-group col-md-4">
                                    {
                                        !this.props.readOnly && <>
                                            <label>Status del ciclo</label>
                                            <span type="text" className="form-control text-muted text-uppercase">
                                                {this.setText(this.current_requisition.status)}
                                            </span>
                                        </> || <>
                                            <small className="text-muted">Status del ciclo</small>
                                            <p className="text-uppercase">
                                                {this.setText(this.current_requisition.status)}
                                            </p>
                                        </>
                                    }
                                    
                                </div>}
                                {(!_.isEmpty(this.groupId) && !_.isEmpty(this.current_requisition.fecha_autorizacion)) && <div className="col-md-4">
                                    <div className="form-group">
                                        {
                                            !this.props.readOnly && <>
                                                <label>Autorizado al</label>
                                                <span type="text" className="form-control text-muted">
                                                    {formatString.setLegibleDate(this.current_requisition.fecha_autorizacion.iso)}
                                                </span>
                                            </> || <>
                                                <small className="text-muted">Autorizado al</small>
                                                <p>
                                                    {formatString.setLegibleDate(this.current_requisition.fecha_autorizacion.iso)}
                                                </p>
                                            </>
                                        }
                                    </div>
                                </div>}
                                {(!_.isEmpty(this.groupId) && !_.isEmpty(this.current_requisition.fecha_rechazo)) && <div className="col-md-4">
                                    <div className="form-group">
                                        {
                                            !this.props.readOnly && <>
                                                <label>Rechazado al</label>
                                                <span type="text" className="form-control text-muted">
                                                    {new Date(this.current_requisition.fecha_rechazo.iso).toLocaleDateString()}
                                                </span>
                                            </> || <>
                                                <small className="text-muted">Rechazado al</small>
                                                <p>
                                                    {new Date(this.current_requisition.fecha_rechazo.iso).toLocaleDateString()}
                                                </p>
                                            </>
                                        }
                                    </div>
                                </div>}
                                {(!_.isEmpty(this.groupId) && !_.isEmpty(this.current_requisition.fecha_finalizacion)) && <div className="col-md-4">
                                    <div className="form-group">
                                        {
                                            !this.props.readOnly && <>
                                                <label>Baja al</label>
                                                <span type="text" className="form-control text-muted">
                                                    {formatString.setLegibleDate(this.current_requisition.fecha_finalizacion.iso)}
                                                </span>
                                            </> || <>
                                                <small className="text-muted">Baja al</small>
                                                <p>
                                                    {formatString.setLegibleDate(this.current_requisition.fecha_finalizacion.iso)}
                                                </p>
                                            </>
                                        }
                                    </div>
                                </div>}
                                {!_.isEmpty(this.current_requisition.status_comentario) && <div className="form-group col-md-4">
                                    
                                        {
                                            !this.props.readOnly && <>
                                                <label>Comentarios del status</label>
                                                <span type="text" className="text-muted p-2 d-block border rounded">
                                                    {this.current_requisition.status_comentario}
                                                </span>
                                            </> || <>
                                                <small className="text-muted">Comentarios del status</small>
                                                <p>{this.current_requisition.status_comentario}</p>
                                            </>
                                        }
                                                
                                </div>}
                            </div>
                            
                            <div className={`form-group rounded ${_.isEmpty(this.groupId) ? 'border' : ''} table-responsive`}>    
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th className="border-0">#</th>
                                            <th className="border-0">Nombre</th>
                                            <th className="border-0" nowrap="true">Monto actual</th>
                                            <th className="border-0" nowrap="true">Monto solicitado</th>
                                            <th className="border-0" nowrap="true">Monto sugerido</th>
                                            {(this.current_requisition.status === 'baja' || this.current_requisition.status === 'autorizado') && <th className="border-0" nowrap="true">Monto autorizado</th>}
                                            {/* <th className="border-0" nowrap="true">Pago <PopOver title="Pago recurrente" content="Esta cantidad representa el pago periódico que realizará el cliente hasta completar el ciclo."/></th> */}
                                            {!this.props.readOnly && <th className="border-0"></th>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.montos.map((socio, index) => <tr key={index}>
                                            <td>{index +1}</td>
                                            <td className="td-responsive-text">
                                                {this.partnersSelected[index].nombre + ' ' +this.partnersSelected[index].apellido_paterno + ' ' + this.partnersSelected[index].apellido_materno}
                                            </td>
                                            <td nowrap="true">
                                                <div className="input-group">
                                                    {
                                                        this.activeNewCycle === true && <>
                                                            <div className="input-group-prepend">
                                                                <div className="input-group-text">$</div>
                                                            </div>
                                                            <input type="number" className="form-control" name={['monto_actual',index]} value={this.state.montos[index].monto_actual} disabled />
                                                        </> || <>
                                                            <div className="input-group-prepend">
                                                                <div className="input-group-text border-0">$</div>
                                                            </div>
                                                            <span className="form-control border-0">{new Intl.NumberFormat('en-MX').format(this.state.montos[index].monto_actual)}</span>
                                                        </>
                                                    }
                                                    
                                                </div>
                                            </td>
                                            <td nowrap="true">
                                                <div className="input-group">
                                                    {
                                                        this.activeNewCycle === true && <>
                                                            <div className="input-group-prepend">
                                                                <div className="input-group-text">$</div>
                                                            </div>
                                                            <input type="number" tabIndex={`amount-input-${index*1}`} className="form-control" min="100" name={['monto_solicitado',index]} value={this.state.montos[index].monto_solicitado} onChange={this.handleChangeAmount} required={this.props.required} />
                                                        </> || <>
                                                            <div className="input-group-prepend">
                                                                <div className="input-group-text border-0">$</div>
                                                            </div>
                                                            <span className="form-control border-0">{new Intl.NumberFormat('en-MX').format(this.state.montos[index].monto_solicitado)}</span>
                                                        </>
                                                    }
                                                </div>
                                            </td>
                                            <td nowrap="true">
                                                <div className="input-group">
                                                    {
                                                        this.activeNewCycle === true && <>
                                                            <div className="input-group-prepend">
                                                                <div className="input-group-text">$</div>
                                                            </div>
                                                            <input type="number" tabIndex={`amount-input-${index*2}`} className="form-control" min="100" name={['monto_sugerido',index]} value={this.state.montos[index].monto_sugerido} onChange={this.handleChangeAmount} required={this.props.required} />
                                                        </> || <>
                                                            <div className="input-group-prepend">
                                                                <div className="input-group-text border-0">$</div>
                                                            </div>
                                                            <span className="form-control border-0">{new Intl.NumberFormat('en-MX').format(this.state.montos[index].monto_sugerido)}</span>
                                                        </>
                                                    }
                                                </div>
                                            </td>
                                            {
                                                (this.current_requisition.status === 'baja' || this.current_requisition.status === 'autorizado') && <td nowrap="true">
                                                    <div className="input-group">
                                                    {
                                                        this.activeNewCycle === true && <>
                                                            <div className="input-group-prepend">
                                                                <div className="input-group-text">$</div>
                                                            </div>
                                                            <input type="number" tabIndex={`amount-input-${index*3}`} className="form-control" min="100" name={['monto_autorizado',index]} value={this.state.montos[index].monto_autorizado} onChange={this.handleChangeAmount} required={this.props.required} disabled={this.props.id_grupo} />
                                                        </> || <>
                                                            <div className="input-group-prepend">
                                                                <div className="input-group-text border-0">$</div>
                                                            </div>
                                                            <span className="form-control border-0">{new Intl.NumberFormat('en-MX').format(this.state.montos[index].monto_autorizado)}</span>
                                                        </>
                                                    }
                                                    </div>
                                                </td>
                                            }
                                            {/* {!!this.state.prestamo_personalizado_interes && <td nowrap="true">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text border-0">$</div>
                                                    </div>
                                                    <span className="form-control border-0">{this.handleSetPay(index)}</span>
                                                </div>
                                            </td>} */}
                                            {!this.props.readOnly && <td nowrap="true">
                                                {this.setBtnRemovePartner(index)}
                                            </td>}
                                        </tr>)}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td className="align-middle" scope="row"><b>Totales</b></td>
                                            <td></td>
                                            <td nowrap="true">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text border-0">$</div>
                                                    </div>
                                                    <span type="text" className="form-control text-info border-0">
                                                        {this.setAmountTotal('monto_actual')}
                                                    </span>
                                                </div>
                                            </td>
                                            <td nowrap="true">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text border-0">$</div>
                                                    </div>
                                                    <span type="text" className="form-control text-info border-0">
                                                        {this.setAmountTotal('monto_solicitado')}
                                                    </span>
                                                </div>
                                            </td>
                                            <td nowrap="true">
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <div className="input-group-text border-0">$</div>
                                                    </div>
                                                    <span type="text" className="form-control text-info border-0">
                                                        {this.setAmountTotal('monto_sugerido')}
                                                    </span>
                                                </div>
                                            </td>
                                            {
                                                (this.current_requisition.status === 'baja' || this.current_requisition.status === 'autorizado') && <td nowrap="true">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <div className="input-group-text border-0">$</div>
                                                        </div>
                                                        <span type="text" className="form-control text-info border-0">
                                                            {this.setAmountTotal('monto_autorizado')}
                                                        </span>
                                                    </div>
                                                </td>
                                            }
                                            {!this.props.readOnly && <td></td>}
                                            {/* <td></td> */}
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>

                            
                        </div>}
                        
                        {(this.partnersSelected.length === 0) && <div className="alert alert-warning m-0" role="alert">
                            Para agregar clientas realizando una búsqueda. <b>Elige al menos 3 clientas.</b>
                        </div>}

                    </div>
                </fieldset>

                <fieldset className="form-group card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-lg-5 col-md-4">
                                <legend>Responsable del grupo</legend>
                                <div className="form-group">
                                    {
                                        !this.props.readOnly && <>
                                            <label htmlFor="responsable">Responsable</label>
                                            <select className="form-control text-uppercase" id="responsable" name="responsable" value={this.state.responsable} onChange={this.handleChangeInputForm} required={this.props.required}>
                                                <option value="">Elige una opción</option>
                                                {this.partnersSelected.map((socio, index) => <option value={socio.objectId} key={index}>
                                                    {socio.nombre + ' ' +socio.apellido_paterno + ' ' + socio.apellido_materno}
                                                </option>)}
                                            </select>
                                        </> || <>
                                            <small className="text-muted">Responsable</small>
                                            {!_.isEmpty(this.state.responsable) && <p>
                                                <span onClick={() => this.openPartnerInfoResponsable()} title="Ver datos de la clienta" className="btn-link" style={{cursor:'pointer'}}>
                                                    {this.setResponsable(this.state.responsable)} <i className="fas fa-external-link-alt"></i>
                                                </span>
                                            </p>}
                                        </>
                                    }
                                    
                                </div>
                                <div className="form-group">
                                {
                                    !this.props.readOnly && <>
                                        <label htmlFor="modo_desembolso">Modo de desembolso</label>
                                        <select className="form-control text-uppercase" id="modo_desembolso" name="modo_desembolso" value={this.state.modo_desembolso} onChange={this.handleChangeInputForm} required={this.props.required}>
                                            <option value="">Elige una opción</option>
                                            <option value="EFECTIVO">
                                                En efectivo
                                            </option>
                                            <option value="BANCO">
                                                Banco
                                            </option>
                                            {/* <option value="TARJETA_DEBITO">
                                                Tarjeta de débito
                                            </option>
                                            <option value="CLABE">
                                                Clabe interbancaria
                                            </option> */}
                                        </select>
                                    </> || <>
                                        <small className="text-muted">Modo de desembolso</small>
                                        <p>
                                        {this.state.modo_desembolso === 'EFECTIVO' && 'EFECTIVO'}
                                        {this.state.modo_desembolso === 'BANCO' && 'BANCO'}
                                        {this.state.modo_desembolso === 'TARJETA_DEBITO' && 'TARJETA DÉBITO'}
                                        {this.state.modo_desembolso === 'CLABE' && 'TARJETA DÉBITO'}
                                        </p>
                                    </>
                                }
                                </div>
                            </div>
                            
                            <div className="col-lg-7 col-md-8">
                                {this.state.modo_desembolso === 'BANCO' && <>
                                    <legend>Desembolso bancario</legend>
                                    {
                                        this.state.cuentas_bancarias.map((item, index) => {return this.htmlBanckPartner(item, index, this.props.readOnly)})
                                    }
                                </>}
                                <div className="row">
                                    <div className="col-md-6">
                                        {/* TDD */}
                                        {!_.isEmpty(this.state.responsable_tdd_banco) && <small className="card">
                                            <div className="card-body p-3">
                                                <small className="badge badge-info text-white mb-2">dato historico</small>
                                                <h5>Datos bancarios del responsable</h5>
                                                <div className="form-group">
                                                    {/* {
                                                        !this.props.readOnly && <>
                                                            <label htmlFor="responsable_tdd_banco">Nombre del banco</label>
                                                            <input type="text" className="form-control text-uppercase" id="responsable_tdd_banco" name="responsable_tdd_banco" value={this.state.responsable_tdd_banco} onChange={this.handleChangeInputForm} required={this.props.required} />
                                                        </> || <> */}
                                                            <small className="text-muted">Nombre del banco</small>
                                                            <p>
                                                                {this.state.responsable_tdd_banco}
                                                            </p>
                                                        {/* </>
                                                    } */}
                                                </div>
                                                <div className="form-group">
                                                    {/* {
                                                        !this.props.readOnly && <>
                                                            <label htmlFor="responsable_tdd_numero">Número de tarjeta</label>
                                                            <MaskedInput 
                                                            type="text" 
                                                            className="form-control" 
                                                            mask={[/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}
                                                            id="responsable_tdd_numero" 
                                                            name="responsable_tdd_numero" 
                                                            value={this.state.responsable_tdd_numero} 
                                                            onChange={this.handleChangeInputForm} 
                                                            guide={false}
                                                            required={this.props.required} />
                                                        </> || <> */}
                                                            <small className="text-muted">Número de tarjeta</small>
                                                            <p>
                                                                <span mask={[/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}>
                                                                    {this.setCreditCard(this.state.responsable_tdd_numero)}
                                                                </span>
                                                            </p>
                                                        {/* </>
                                                    } */}
                                                </div>
                                                <div className="form-group">
                                                    {/* {
                                                        !this.props.readOnly && <>
                                                            <label htmlFor="responsable_tdd_titular">Nombre completo del titular de la tarjeta</label>
                                                            <input type="text" className="form-control text-uppercase" id="responsable_tdd_titular" name="responsable_tdd_titular" value={this.state.responsable_tdd_titular} onChange={this.handleChangeInputForm} required={this.props.required} />
                                                        </> || <> */}
                                                            <small className="text-muted">Nombre completo del titular de la tarjeta</small>
                                                            <p>
                                                                {this.state.responsable_tdd_titular}
                                                            </p>
                                                        {/* </>
                                                    } */}
                                                </div>
                                            </div>
                                        </small>}
                                    </div>
                                    <div className="col-md-6">
                                        {/* CLABE */}
                                        {!_.isEmpty(this.state.responsable_clabe_banco) && <small className="card">
                                            <div className="card-body p-3">
                                                <small className="badge badge-info text-white mb-2">dato historico</small>
                                                <h5>Datos bancarios del responsable</h5>
                                                <div className="form-group">
                                                    {/* {
                                                        !this.props.readOnly && <>
                                                            <label htmlFor="responsable_clabe_banco">Nombre del banco</label>
                                                            <input type="text" className="form-control text-uppercase" id="responsable_clabe_banco" name="responsable_clabe_banco" value={this.state.responsable_clabe_banco} onChange={this.handleChangeInputForm} required={this.props.required} />
                                                        </> || <> */}
                                                            <small className="text-muted">Nombre del banco</small>
                                                            <p>
                                                                {this.state.responsable_clabe_banco}
                                                            </p>
                                                        {/* </>
                                                    } */}
                                                </div>
                                                <div className="form-group">
                                                    {/* {
                                                        !this.props.readOnly && <>
                                                            <label htmlFor="responsable_clabe">Clabe interbancaria</label>
                                                            <input 
                                                            type="text" 
                                                            className="form-control"
                                                            id="responsable_clabe" 
                                                            name="responsable_clabe"
                                                            value={this.state.responsable_clabe} 
                                                            onChange={this.handleChangeClabe}
                                                            required={this.props.required} />
                                                        </> || <> */}
                                                            <small className="text-muted">Clabe interbancaria</small>
                                                            <p>
                                                                {this.state.responsable_clabe}
                                                            </p>
                                                        {/* </>
                                                    } */}
                                                </div>
                                                <div className="form-group">
                                                    {/* {
                                                        !this.props.readOnly && <>
                                                            <label htmlFor="responsable_clabe_titular">Nombre completo del titular de la cuenta</label>
                                                            <input type="text" className="form-control text-uppercase" id="responsable_clabe_titular" name="responsable_clabe_titular" value={this.state.responsable_clabe_titular} onChange={this.handleChangeInputForm} required={this.props.required} />
                                                        </> || <> */}
                                                            <small className="text-muted">Nombre completo del titular de la cuenta</small>
                                                            <p>
                                                                {this.state.responsable_clabe_titular}
                                                            </p>
                                                        {/* </>
                                                    } */}
                                                </div>
                                            </div>
                                        </small>}
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        {this.state.montos.length === 1 && <div className="mt-3">
                            <legend>Aval</legend>
                            {!this.props.readOnly && <p className="text-info">Se requiere un aval para la única clienta del grupo</p>}
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        {
                                            !this.props.readOnly && <>
                                                <label htmlFor="aval_nombre">Nombre</label>
                                                <input type="text" className="form-control text-uppercase" id="aval_nombre" name="aval_nombre" value={this.state.aval.nombre} onChange={this.handleChangeAval} required />
                                            </> || <>
                                                <small className="text-muted">Nombre</small>
                                                <p>
                                                    {this.state.aval.nombre}
                                                </p>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        {
                                            !this.props.readOnly && <>
                                                <label htmlFor="aval_apellido_paterno">Apellido paterno</label>
                                                <input type="text" className="form-control text-uppercase" id="aval_apellido_paterno" name="aval_apellido_paterno" value={this.state.aval.apellido_paterno} onChange={this.handleChangeAval} required />
                                            </> || <>
                                                <small className="text-muted">Apellido paterno</small>
                                                <p>
                                                    {this.state.aval.apellido_paterno}
                                                </p>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        {
                                            !this.props.readOnly && <>
                                                <label htmlFor="aval_apellido_materno">Apellido materno</label>
                                                <input type="text" className="form-control text-uppercase" id="aval_apellido_materno" name="aval_apellido_materno" value={this.state.aval.apellido_materno} onChange={this.handleChangeAval} required />
                                            </> || <>
                                                <small className="text-muted">Apellido materno</small>
                                                <p>
                                                    {this.state.aval.apellido_materno}
                                                </p>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="form-group">
                                        {
                                            !this.props.readOnly && <>
                                                <label htmlFor="aval_domicilio_direccion">Dirección</label>
                                                <input type="text" className="form-control text-uppercase" id="aval_domicilio_direccion" name="aval_domicilio_direccion" value={this.state.aval.domicilio_direccion} onChange={this.handleChangeAval} required />
                                            </> || <>
                                                <small className="text-muted">Dirección</small>
                                                <p>
                                                    {this.state.aval.domicilio_direccion}
                                                </p>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        {
                                            !this.props.readOnly && <>
                                                <label htmlFor="aval_domicilio_cp">C.P.</label>
                                                <input type="text" className="form-control text-uppercase" id="aval_domicilio_cp" name="aval_domicilio_cp" value={this.state.aval.domicilio_cp} onChange={this.handleChangeAval} required />
                                            </> || <>
                                                <small className="text-muted">C.P.</small>
                                                <p>
                                                    {this.state.aval.domicilio_cp}
                                                </p>
                                            </>
                                        }
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        {
                                            !this.props.readOnly && <>
                                                <label htmlFor="aval_domicilio_entidad">Estado</label>
                                                <input type="text" className="form-control text-uppercase" id="aval_domicilio_entidad" name="aval_domicilio_entidad" value={this.state.aval.domicilio_entidad} onChange={this.handleChangeAval} required />
                                            </> || <>
                                                <small className="text-muted">Estado</small>
                                                <p>
                                                    {this.state.aval.domicilio_entidad}
                                                </p>
                                            </>
                                        }
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        {
                                            !this.props.readOnly && <>
                                                <label htmlFor="aval_domicilio_poblacion">Población</label>
                                                <input type="text" className="form-control text-uppercase" id="aval_domicilio_poblacion" name="aval_domicilio_poblacion" value={this.state.aval.domicilio_poblacion} onChange={this.handleChangeAval} required />
                                            </> || <>
                                                <small className="text-muted">Población</small>
                                                <p>
                                                    {this.state.aval.domicilio_poblacion}
                                                </p>
                                            </>
                                        }
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="form-group">
                                        {
                                            !this.props.readOnly && <>
                                                <label htmlFor="aval_domicilio_colonia">Fraccionamiento o Colonia</label>
                                                <input type="text" className="form-control text-uppercase" id="aval_domicilio_colonia" name="aval_domicilio_colonia" value={this.state.aval.domicilio_colonia} onChange={this.handleChangeAval} required />
                                            </> || <>
                                                <small className="text-muted">Fraccionamiento o Colonia</small>
                                                <p>
                                                    {this.state.aval.domicilio_colonia}
                                                </p>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>}

                    </div>
                </fieldset>
                
                
            </>
        );
    }

    htmlBanckPartner = (data, index) => {
        return (
            <div className="mb-4 d-flex" key={index}>
                {(this.props.readOnly === false || this.props.readOnly === undefined) && <div>
                    <div className="btn-toolbar mr-2" role="toolbar" aria-label="Toolbar with button groups">
                        <div className="btn-group-vertical" role="group" aria-label="Add/Remove">
                            <button type="button" className="btn btn-secondary" onClick={() => this.addItem(index)} disabled={this.state.cuentas_bancarias.length === this.partnersSelected.length}>
                                <i className="fas fa-plus"></i>
                            </button>
                            <button type="button" className="btn btn-secondary" onClick={() => this.removeItem(index)}>
                                <i className="fas fa-minus"></i>
                            </button>
                        </div>
                    </div>
                </div>}
                <div className="card bg-light w-100">
                    <div className="card-body p-3">
                        <div className="row">
                            <div className="col-md-7">
                                {((this.props.readOnly === false || this.props.readOnly === undefined) && <div className="form-group">
                                    <label htmlFor={`socio-item${index}`}>Clienta</label>
                                    <select className="form-control text-uppercase" id={`socio-item${index}`} name="socio" value={data.Socio.objectId} required={true} onChange={(event) => this.handleChangeBanckPartner(event, index)}>
                                        <option value="">Elige una opción</option>
                                        {this.partnersSelected.map((socio, index) => <option value={socio.objectId} key={index}>
                                            {socio.nombre + ' ' +socio.apellido_paterno + ' ' + socio.apellido_materno}
                                        </option>)}
                                    </select>
                                </div>) || <>
                                    <small className="text-muted">Clienta</small>
                                    <p>
                                        {this.partnersSelected.map((socio, index) => socio.objectId === data.Socio.objectId && <span key={index}>
                                            {socio.nombre + ' ' +socio.apellido_paterno + ' ' + socio.apellido_materno}
                                        </span>)}
                                    </p>
                                </>}
                            </div>
                            <div className="col-md-5">
                                {((this.props.readOnly === false || this.props.readOnly === undefined) && <div className="form-group">
                                    <label htmlFor={`tipo-item${index}`}>Transferir a</label>
                                    <select className="form-control text-uppercase" id={`tipo-item${index}`} name="tipo" value={data.tipo} required={true} onChange={(event) => this.handleChangeBanckPartner(event, index)}>
                                        <option value="">Elige una opción</option>
                                        <option value="tarjeta">
                                            TARJETA DE DÉBITO
                                        </option>
                                        <option value="clabe">
                                            CLABE INTERBANCARIA
                                        </option>
                                    </select>
                                </div>) || <>
                                    <small className="text-muted">Transferir a</small>
                                    <p>
                                        {this.partnersSelected.map((socio, index) => socio.objectId === data.Socio.objectId && <span key={index}>
                                            {data.tipo === 'tarjeta' && 'TARJETA DE DÉBITO'}
                                            {data.tipo === 'clabe' && 'CLABE INTERBANCARIA'}
                                        </span>)}
                                    </p>
                                </>}
                            </div>
                        </div>
                        {/* {((this.props.readOnly === false || this.props.readOnly === undefined) && <div className="mb-3">
                            <label htmlFor={`tipo-tarjeta-item${index}`}>Transferir a</label>
                            <div className="form-group">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="tipo" id={`tipo-tarjeta-item${index}`} value="tarjeta" checked={data.tipo === 'tarjeta'} defaultChecked required={true} onChange={(event) => this.handleChangeBanckPartner(event, index)}/>
                                    <label className="form-check-label" htmlFor={`tipo-tarjeta-item${index}`}>
                                    TARJETA DE DÉBITO
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="tipo" id={`tipo-clabe-item${index}`} value="clabe" checked={data.tipo === 'clabe'} required={true} onChange={(event) => this.handleChangeBanckPartner(event, index)}/>
                                    <label className="form-check-label" htmlFor={`tipo-clabe-item${index}`}>
                                    Clabe interbancaria
                                    </label>
                                </div>
                            </div>
                        </div>) || <>
                            <small className="text-muted">Transferir a</small>
                            <p>
                                {this.partnersSelected.map((socio, index) => socio.objectId === data.Socio.objectId && <span key={index}>
                                    {data.tipo === 'tarjeta' && 'TARJETA DE DÉBITO'}
                                    {data.tipo === 'clabe' && 'CLABE INTERBANCARIA'}
                                </span>)}
                            </p>
                        </>} */}
                        <div className="row">
                            <div className="col-md-5">
                                {((this.props.readOnly === false || this.props.readOnly === undefined) && <div className="form-group">
                                    <label htmlFor={`banco-item${index}`}>Banco</label>
                                    <input type="text" className="form-control" id={`banco-item${index}`} name="banco" value={data.banco} required={true} onChange={(event) => this.handleChangeBanckPartner(event, index)}/>
                                </div>) || <>
                                    <small className="text-muted">Banco</small>
                                    <p>
                                        {data.banco.toUpperCase()}
                                    </p>
                                </>}
                            </div>
                            <div className="col-md-7">
                                 {((this.props.readOnly === false || this.props.readOnly === undefined) && <div className="form-group">
                                    <label htmlFor={`cuenta-item${index}`}>Número de {(data.tipo === 'tarjeta' && 'tarjeta') || (data.tipo === 'clabe' && 'clabe interbancaria')}</label>
                                    <input type="text" className="form-control" id={`cuenta-item${index}`} name="cuenta" value={data.cuenta} required={true} onChange={(event) => this.handleChangeBanckPartner(event, index)}/>
                                </div>) || <>
                                    <small className="text-muted">Número de {(data.tipo === 'tarjeta' && 'tarjeta') || (data.tipo === 'clabe' && 'clabe interbancaria')}</small>
                                    <p>
                                        {data.cuenta}
                                    </p>
                                </>}
                            </div>
                        </div>
                        {((this.props.readOnly === false || this.props.readOnly === undefined) && <div className="form-group">
                            <label htmlFor={`titular-item${index}`}>Titular de la {(data.tipo === 'tarjeta' && 'tarjeta') || (data.tipo === 'clabe' && 'clabe interbancaria')}</label>
                            <input type="text" className="form-control" id={`titular-item${index}`} name="titular" value={data.titular} required={true} onChange={(event) => this.handleChangeBanckPartner(event, index)}/>
                        </div>) || <>
                            <small className="text-muted">Titular de la {(data.tipo === 'tarjeta' && 'tarjeta') || (data.tipo === 'clabe' && 'clabe interbancaria')}</small>
                            <p>
                                {data.titular}
                            </p>
                        </>}
                    </div>
                </div>
            </div>
        )
    }
}

export default GroupForm;